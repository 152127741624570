import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {QueryClient, QueryClientProvider} from "react-query";
import '@/styles/tailwind.css';
import '@/styles/fontawesome.css';
import '@/styles/custom.css';
import DataProvider from "@/_helpers/Context";
import {DevSupport} from "@react-buddy/ide-toolbox";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,//quand on revient sur la fenêtre ça ne se refetch pas
            refecthOnmount: false,//quand on monte le composant ça ne se refetch pas
            refetchOnReconnect: false,//quand on reconnecte ça ne se refetch pas
            retry: false,//pas de retry
            staleTime: 5 * 60 * 1000,//requête valable 5 minutes
        }
    }
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <QueryClientProvider client={queryClient}>
        <DataProvider>
                <App/>
        </DataProvider>
    </QueryClientProvider>
);
