import { useState } from "react"
import {Link, useNavigate} from "react-router-dom";
import { accountService } from "@/_services";
import Alert from "../../components/Alert";

const PasswordLost = () => {

    let navigate = useNavigate()
    const [error, setError] = useState(null);
    const [errorType, setErrorType] = useState(null);
    const [credentials, setCredentials] = useState({
        username: '',
        password: ''
    })

    const onChange = (e) => {
        setCredentials({
            ...credentials,//ancien état du state
            [e.target.name]: e.target.value //fusion avec le nouveau state
        })
    }


    const onSubmit = (e) => {
        e.preventDefault();
        accountService.login(credentials)
            .then(res => {
                accountService.saveToken(res.data.token);
                accountService.saveRefreshToken(res.data.refresh_token);
                setError(null);
                navigate('/admin', { replace: true });
            })
            .catch(error => {
                if (error.response) {
                    let errorMessage = "Une erreur est survenue lors de la connexion au serveur. Veuillez réessayer plus tard.";
                    let errorType = "error";

                    switch (error.response.status) {
                        case 400:
                            errorMessage = "Invalid username or password";
                            errorType = "warning";
                            break;
                        case 401:
                            errorMessage = "Unauthorized access";
                            break;
                        case 404:
                        case 500:
                            errorMessage = `Error ${error.response.status}: ${error.response.data.title}`;
                            break;
                        default:
                            errorMessage = "An unexpected error occurred.";
                            break;
                    }

                    setError(errorMessage);
                    setErrorType(errorType);
                } else {
                    setError("Une erreur est survenue lors de la connexion au serveur. Veuillez réessayer plus tard.");
                    setErrorType("error");
                }
            });
    };


    return (
        <div className="min-h-screen bg-base-200 flex items-center">
            <div className="card mx-auto w-full max-w-xl bg-base-100 shadow-xl">
                <div className='p-10'>
                    <h1 className={"text-2xl font-bold text-center mb-10"}>Reset password</h1>
                    <form onSubmit={onSubmit}>
                        <input type="text" name="username" className="input  input-bordered w-full mb-5" placeholder="Enter your email" value={credentials.username} onChange={onChange} />
                        <button className="btn btn-primary w-full ">Reset password</button>
                        <Link to="/" className="text-sm text-primary">Login</Link>

                        {error &&  <div className={"mt-5"}> <Alert type={errorType} message={error} /></div>  }
                    </form>
                </div>
            </div>
        </div>
    );
};

export default PasswordLost;