// Logger.js

const setLogStyle = (logType) => {
    let style;
    if (logType === 'error') {
        style = [
            'background: red',
            'color: #fff',
            'font-weight: bold',
            'padding: 5px 120px',
            'line-height: 35px',
            'border-radius: 5px'
        ].join(';');
    } else if (logType === 'debug') {
        style = [
            'background: #2cb7dd',
            'color: #fff',
            'font-weight: bold',
            'padding: 5px 120px',
            'width: 200px',
            'line-height: 35px',
            'border-radius: 5px'
        ].join(';');
    }
    return style;
};

const setMsgStyle = () => {
    const style = [
        'color: #fff',
        'padding: 10px 20px',
        'line-height: 15px'
    ].join(';');
    return style;
}

const Logger = (logType, message) => {
    const logStyle = setLogStyle(logType);
    const msgLogStyle = setMsgStyle();

    // Vérifier si le message est un objet
    if (typeof message === 'object') {
        console.log('%c /!\\ ' + logType.toUpperCase() + ' /!\\', logStyle);
        console.log(message);
    } else {
        console.log('%c /!\\ ' + logType.toUpperCase() + ' /!\\ %c' + message, logStyle, msgLogStyle);
    }
};

export default Logger;
