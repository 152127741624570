import Axios from "@/_services/caller.service";


let login = (credentials) => {
    return Axios.post('system/login', credentials, {
        headers: {
            'Accept' : 'application/json',
            'Content-Type' : 'application/json'
        }
    });
}

let saveToken = (token) => {
    localStorage.setItem('token', token)
}

let saveRefreshToken = (refreshToken) => {
    localStorage.setItem('refreshToken', refreshToken)
}

let getRefreshToken = () => {
    return localStorage.getItem('refreshToken')
}

let logout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    return Axios.post('token/invalidate');
}

let getToken = () => {
    return localStorage.getItem('token')

}

let getNewRefreshToken = async () => {
    try {
         await Axios.post('/token/refresh', {
            refresh_token: getRefreshToken(),
        }, {
            headers: {
                'Authorization': 'Bearer ' + getToken(),
            },
        });

    } catch (error) {
        // Déconnexion de l'utilisateur en cas d'erreur 401
        if (error.response && error.response.status === 401) {
            if (window.confirm('Account-service ; Votre session a expiré, voulez-vous vous reconnecter ?')) {
                accountService.logout();
                window.location.href = '/login';
            }else {
               console.log("Account-service : NOPE")
            }

        } else {
            // Rejeter l'erreur pour la gestion ultérieure
            return Promise.reject(error);
        }
    }
};




let isLogged = () => {

    return !!localStorage.getItem('token');
}

export const accountService = {
    getToken,
    login,
    saveToken,
    saveRefreshToken,
    getNewRefreshToken,
    getRefreshToken,
    logout,
    isLogged
}