import ThemeSwitcher from "@/_utils/ThemeSwitcher";
import AnimalIcon from "@/_utils/AnimalIcon";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/pro-regular-svg-icons";
import {accountService} from "@/_services/account.service";
import {userService} from "@/_services/users.service";
import { useNavigate } from "react-router-dom";
import {faBars} from "@fortawesome/pro-light-svg-icons";
import React, {useEffect, useState} from "react";
const Header = () => {
    let navigate = useNavigate()
    const [user, setUser] = useState({
        name: '',
        firstName: ''
    });
    const logout = () => {
        accountService.logout()
            .then(() => {
                navigate('/');
            })
            .catch(error => {
                navigate('/');
                console.error("Une erreur s'est produite lors de la déconnexion: ", error.message);
            });
    }




    useEffect(() => {
        userService.getCurrentUser()
            .then(res => {
                const { name, firstname, apiToken} = res.data;
                setUser({ name, firstname, apiToken });

            })
            .catch(err => console.log(err));
    }, []);




    return (
        <div>
            <div className="navbar  flex justify-between bg-base-100  z-10 shadow-md ">
                {/* Menu toogle for mobile view or small screen */}
                <div className="">
                    <label htmlFor="left-sidebar-drawer" className="btn btn-primary drawer-button lg:hidden">
                        <FontAwesomeIcon icon={faBars} className="text-xl"/>
                    </label>
                </div>

                <div className="order-last pr-5">
                    {/* Light and dark theme selection toogle **/}
                    <button className="btn btn-ghost ml-4  btn-circle">
                        <ThemeSwitcher/>
                    </button>

                    {/* Profile icon, opening menu on click */}
                    <div className="dropdown dropdown-end ml-4">
                        <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
                            <FontAwesomeIcon icon={faUser} className="text-xl" />
                        </label>
                        <ul tabIndex={0}
                            className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-60">
                            <div className=" py-3">
                                <span className="text-5xl absolute top-4 left-3 -z-10 opacity-90">
                                   <AnimalIcon />
                                </span>
                                <h4 className="font-bold text-lg relative pl-16 leading-5">  {user.name} <br />{user.firstname}</h4>
                            </div>
                            <div className="divider mt-0 mb-0"></div>
                           {/* <li className="justify-between">
                                <Link to={'/admin/users/profile'}>
                                    Profile Settings
                                </Link>
                            </li>*/}
                            <li><div onClick={logout}>Logout</div></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
