import Axios from "./caller.service";

let getAllChangelogs = async () => {
    const {data} = await Axios.get('/system/getchangelogs',
        {
            headers: {
                'X-AUTH-TOKEN' : '6a6047295b95dbcd5830a66c49e4ce56ba3e4e91cf8a39ad891c4babe845e6ab',
            }
        })
    return data
}

let getChangelog = (id) => {
    return Axios.get('/system/changelogs/'+id)
}

let addChangelog = (changelog) => {
    return Axios.post(`/system/changelogs`, changelog)
}

let updateChangelog = (uid, changelog) => {
    return Axios.put(`/system/changelog/${uid}`, changelog)  // Utilisez le paramètre uid ici
}

let deleteChangelog = (uid) => {
    return Axios.delete(`/system/changelog/${uid}`)
}

// Fonction pour vérifier si un changelog en ligne existe pour une catégorie donnée
let checkOnlineChangelogExists = async (category) => {
    const allChangelogs = await getAllChangelogs();
    return allChangelogs.some(changelog => changelog.status === 'online' && changelog.category === category);
};

let getOnlineChangelog = async (category) => {
    const allChangelogs = await getAllChangelogs();
    return allChangelogs.find(changelog => changelog.status === 'online' && changelog.category === category);
};

export const changelogsService = {
    checkOnlineChangelogExists,
    getOnlineChangelog,
    getAllChangelogs,
    getChangelog,
    addChangelog,
    updateChangelog,
    deleteChangelog
}