import React, {useState} from 'react';
import {userService} from "@/_services";
import {Link, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faUsers} from "@fortawesome/pro-light-svg-icons";
import {faAt, faEdit, faLockKeyhole, faSignature} from "@fortawesome/pro-solid-svg-icons";
import InputUploadImage from "../../../_utils/InputUploadImage";
import {faSitemap, faUserPlus} from "@fortawesome/pro-regular-svg-icons";
import Breadcrumb from "../../../components/admin/Breadcrumbs";

const UserAdd = () => {

    const navigate = useNavigate();
    const [user, setUser] = useState({
        name: '',
        firstname: '',
        email: '',
        role: 'ROLE_DEV', // Valeur par défaut pour le rôle
        password: ''
    });
    const onChange = (e) => {
        const {name, value} = e.target;
        setUser(prevUser => ({
            ...prevUser,
            [name]: value
        }));
    }

    const onSubmit = (e) => {
        e.preventDefault()
        userService.addUser(user)
            .then(res => {
                navigate('../index')
            })
            .catch(err => console.log(err))
    }
    const handleImageChange = (e) => {
        console.log(e)
        /*const file = e.target.files[0];
        setUser({...user, profileImage: file});*/
    };

    return (
        <div>
            <div className="bg-base-100 rounded-xl shadow-md px-6 pt-6 mb-6">
                <Breadcrumb
                    firstItem={{ text: 'Utilisateurs', link: '/admin/users/index', icon: faUsers }}
                    secondItem={{ text: 'Nouveau', icon: faPencil }}
                />



                <form onSubmit={onSubmit}>
                    <div className="flex justify-between align-middle">
                        <div className="w-2/4">
                            <div className="grid  gap-2 pr-4">
                                <label className="form-control w-full mb-4">
                                    <span className="label-text">
                                        <FontAwesomeIcon icon={faSignature} className={"text-secondary mr-1"} />
                                        Nom
                                    </span>
                                    <input
                                        type="text"
                                        name="name"
                                        placeholder="Name"
                                        value={user.name}
                                        onChange={onChange}
                                        className="input input-bordered w-full"
                                    />
                                </label>
                                <label className="form-control w-full  mb-4">
                                    <span className="label-text">
                                        <FontAwesomeIcon icon={faSignature} className={"text-secondary mr-1"} />
                                        Prénom
                                    </span>
                                <input type="text" name="firstname" placeholder="First Name" value={user.firstname}
                                       onChange={onChange}
                                       className="input  input-bordered w-full"/>
                                </label>
                                <label className="form-control w-full  mb-4">
                                    <span className="label-text">
                                        <FontAwesomeIcon icon={faSignature} className={"text-secondary mr-1"} />
                                        Email
                                    </span>
                                <input type="text" name="email" placeholder="email" value={user.email}
                                       onChange={onChange}
                                       className="input  input-bordered w-full"/>
                                </label>
                                <label className="form-control w-full  mb-4">
                                    <span className="label-text">
                                        <FontAwesomeIcon icon={faSitemap} className={"text-secondary mr-1"} />
                                        Role
                                    </span>
                                <select
                                    value={user.role??"ROLE_DEV"}
                                    name="role"
                                    onChange={onChange}
                                    className="input input-bordered w-full "
                                >
                                    <option value="ROLE_DEV">Developer</option>
                                    <option value="ROLE_ADMIN">Admin</option>
                                    <option value="ROLE_HELPDESK">Helpdesk</option>
                                </select>
                                </label>
                                <label className="form-control w-full  mb-4">
                                    <span className="label-text">
                                        <FontAwesomeIcon icon={faAt} className={"text-secondary mr-1"} />
                                        Password
                                    </span>
                                <input type="text" name="password" placeholder="password" value={user.password}
                                       onChange={onChange}
                                       className="input  input-bordered w-full"/>
                                </label>
                            </div>
                            <div className="card-actions justify-between align-middle mt-2 pr-3  mb-4">
                                <button
                                    type="submit"
                                    className="btn btn-primary w-full"
                                >
                                    <FontAwesomeIcon icon={faUserPlus} />
                                    Créer l'utilisateur
                                </button>
                            </div>
                        </div>
                        <div className="w-2/4 h-auto flex">
                            <InputUploadImage onChange={handleImageChange} />
                        </div>
                    </div>
                </form>

            </div>
        </div>
);
};

export default UserAdd;