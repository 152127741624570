import Axios from "./caller.service";

let getAllStats = async () => {
    const {data} = await Axios.get('/system/dashboard',
        {
            headers: {
                'X-AUTH-TOKEN' : '6a6047295b95dbcd5830a66c49e4ce56ba3e4e91cf8a39ad891c4babe845e6ab',
            }
        })
    return data
}

export const dashboardService = {
    getAllStats,
}