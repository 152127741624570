// src/pages/Users.js
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit, faTrash, faUsers, faUserPlus, faNotes, faCommentLines} from '@fortawesome/pro-light-svg-icons';
import Alert from '../../../components/Alert';
import AnimalIcon from '../../../_utils/AnimalIcon';
import { useData } from '@/_helpers/Context';
import { userService } from '@/_services/users.service';
import Breadcrumb from '@/components/admin/Breadcrumbs';


const Users = () => {
    const { data, setSpecificData } = useData();
    const { users, currentUser } = data;
    const navigate = useNavigate();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);

    //Breadcrumb items
    const homeItem = {text: 'Utilisateurs', link: '/admin/users/index', icon: faUsers};
    const breadcrumbItems = [homeItem];
    const delUser = (userId) => {
        setShowConfirmation(false); // Cacher la confirmation après la suppression
        userService.deleteUser(userId)
            .then(() => {
                setSpecificData('users', users.filter(user => user.id !== userId));
            })
            .catch(err => console.log(err));
    };

    const handleNewClick = () => {
        navigate('/admin/users/add');
    };

    const handleEditClick = (user) => {
        navigate(`/admin/users/edit/${user.id}`, { state: { user } });
    };

    const roleTranslations = {
        ROLE_HELPDESK: {
            text: "Helpdesk",
            colorClass: "inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 dark:bg-teal-950 dark:text-emerald-300 dark:ring-green-900/20"
        },
        ROLE_ADMIN: {
            text: "Administrateur",
            colorClass: "inline-flex items-center rounded-full bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10 dark:bg-indigo-950 dark:text-indigo-300 dark:ring-indigo-900/10"
        },
        ROLE_DEV: {
            text: "Dev",
            colorClass: "inline-flex items-center rounded-full bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-700/10 dark:bg-yellow-950 dark:text-yellow-300 dark:ring-yellow-900/10"
        }
    };

    const getRoleInfo = (role) => {
        return roleTranslations[role] || { text: "Inconnu", colorClass: "bg-gray-500 text-white" };
    };

    return (
        <div className="overflow-x-auto">
            {/* Confirmation modal */}
            <dialog id="confirmation_modal" className="modal">
                <div className="modal-box">
                    <Alert type={"error"} message={"Voulez-vous vraiment supprimer cet utilisateur?"} />
                    <div className="modal-action">
                        <form method="dialog">
                            <button className="btn btn-primary  mr-2"
                                    onClick={() => delUser(userToDelete.id)}>Oui
                            </button>
                            <button className="btn" onClick={() => setShowConfirmation(false)}>Non</button>
                        </form>
                    </div>
                </div>
            </dialog>

            <div className="card w-full p-3 bg-base-100 rounded-xl shadow-md overflow-hidden h-[80vh] mb-10 ">
                <div className="flex justify-between align-middle mb-6">
                    <Breadcrumb
                        firstItem={{ text: 'Utilisateurs', link: '/admin/users/index', icon: faUsers }}
                    />

                    <div className="flex align-middle justify-center">
                        <div className="btn btn-primary btn-sm float-right "
                             onClick={handleNewClick}>
                            <FontAwesomeIcon icon={faUserPlus} /> Créer un utilisateur
                        </div>
                    </div>
                </div>
                <div className="overflow-y-auto">
                    <table className="table table-xs table-pin-rows w-full">
                        <thead>
                        <tr>
                            <th></th>
                            <th>Nom</th>
                            <th>Prénom</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {users.map(user => (
                            <tr key={user.id} className="hover">
                                <td>
                                    <div className="avatar placeholder">
                                        <div className="bg-base-200 text-neutral-content rounded-full w-8">
                                            <span className="text-lg"><AnimalIcon /></span>
                                        </div>
                                    </div>
                                </td>
                                <td>{user.name}</td>
                                <td className="capitalize">{user.firstname}</td>
                                <td>{user.email}</td>
                                <td className="capitalize">
                                    {user.roles && user.roles.length > 0 ? (
                                        user.roles.map(role => {
                                            if (role !== "ROLE_USER" && role !== "ROLE_IS_2FA_SUCCEED" && role !== "ROLE_IS_2FA_IN_PROGRESS") {
                                                const roleInfo = getRoleInfo(role);
                                                return (
                                                    <span key={role} className={`badge ${roleInfo.colorClass}`}>
                                                            {roleInfo.text}
                                                        </span>
                                                );
                                            }
                                        })
                                    ) : (
                                        'Aucun rôle'
                                    )}
                                </td>
                                <td className="text-right">
                                    <div className="flex justify-end">

                                    <div
                                        onClick={() => handleEditClick(user)}
                                        className="btn btn-square btn-ghost mr-2"
                                    >
                                        <FontAwesomeIcon icon={faEdit} className="h-5 w-5" />
                                    </div>
                                    <button className="btn btn-square btn-ghost"
                                            onClick={() => {
                                                setUserToDelete(user);
                                                setShowConfirmation(true);
                                                document.getElementById('confirmation_modal').showModal();
                                            }}
                                    >
                                        <FontAwesomeIcon icon={faTrash} className="h-5 w-5" />
                                    </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Users;
