import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEdit,
    faTrash,
    faArrowUpShortWide,
    faArrowDownShortWide,
    faFilter,
    faPlus, faNotes
} from "@fortawesome/pro-light-svg-icons";
import {changelogsService} from "@/_services";
import Alert from "@/components/Alert";
import withData from '@/_utils/Hoc';
import Content from '@/components/admin/Content';
import Breadcrumb from '@/components/admin/Breadcrumbs';


const Changelogs = withData(({data}) => {
    const [changelogs, setChangelogs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sortOrder, setSortOrder] = useState('desc');
    const navigate = useNavigate();
    const [changelogToDelete, setChangelogToDelete] = useState(null);
    const [filterCategory, setFilterCategory] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [showConfirmation, setShowConfirmation] = useState(false);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await changelogsService.getAllChangelogs();
                const sortedChangelogs = data.sort((a, b) => {
                    if (sortOrder === 'asc') {
                        return new Date(a.date) - new Date(b.date);
                    } else {
                        return new Date(b.date) - new Date(a.date);
                    }
                });
                setIsLoading(false);
                setChangelogs(sortedChangelogs);
                console.log('changelogs', sortedChangelogs)
            } catch (error) {
                setError(error);
                setIsLoading(false);
            }
        };
        fetchData();

    }, [sortOrder]);

    const handleEditClick = (changelog) => {
        navigate(`/admin/changelogs/edit/${changelog.id}`, {state: {changelog}});
    };

    const handleNewClick = () => {
        navigate(`/admin/changelogs/add`, { state: { changelogs } });
    };

    const delChangeLog = (changelogId) => {
        setShowConfirmation(false); // Cacher la confirmation après la suppression
        changelogsService.deleteChangelog(changelogId)
            .then(() => {
                const updatedChangelogs = changelogs.filter(changelog => changelog.id !== changelogId);
                setChangelogs(updatedChangelogs); // Mettre à jour l'état local
            })
            .catch(err => console.error(err));
    };


    const getStatusColorClass = (status) => {
        switch (status) {
            case "online":
                return "inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 dark:bg-teal-950 dark:text-emerald-300 dark:ring-green-900/20";
            case "archived":
                return "inline-flex items-center rounded-full bg-neutral-50 px-2 py-1 text-xs font-medium text-neutral-700 ring-1 ring-inset ring-neutral-600/20 dark:bg-neutral-700 dark:text-neutral-300 dark:ring-neutral-600/20";
            case "draft":
                return "inline-flex items-center rounded-full bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10 dark:bg-blue-950 dark:text-indigo-300 dark:ring-indigo-900/10";
            default:
                return "bg-gray-100 text-gray-700";
        }
    };
    const formatDate = (dateString) => {
        const options = {day: 'numeric', month: 'numeric', year: 'numeric'};
        return new Date(dateString).toLocaleDateString(undefined, options);
    };


    const applyCategoryFilter = (category) => {
        setFilterCategory(category);
    };

    const clearCategoryFilter = () => {
        setFilterCategory(null);
    };

    const filteredChangelogs = filterCategory ? changelogs.filter(changelog => changelog.category === filterCategory) : changelogs;

    return (
        <div className="overflow-x-auto">
            {/* Confirmation modal */}
            <dialog id="confirmation_modal" className="modal">
                <div className="modal-box">
                    <Alert type={"error"} message={"Voulez-vous vraiment supprimer ce changelog?"}/>
                    <div className="modal-action">
                        <form method="dialog">
                            <button className="btn btn-primary  mr-2"
                                    onClick={() => delChangeLog(changelogToDelete.id)}>Oui
                            </button>
                            <button className="btn" onClick={() => setShowConfirmation(false)}>Non</button>
                        </form>
                    </div>
                </div>
            </dialog>

            <Content>
                <div className="flex lg:justify-between  lg:items-center items-start p-3 lg:flex-row flex-col ">
                    <Breadcrumb
                        firstItem={{ text: 'Changelogs',  icon: faNotes }}
                    />


                    <div className="flex align-middle justify-center gap-2">
                        <div className="dropdown lg:dropdown-left dropdown-bottom ">
                            <div tabIndex={0} role="button"
                                 className={filterCategory === null ? 'btn btn-sm btn-neutral' : ' btn btn-sm btn-secondary text-white '}>
                                <FontAwesomeIcon icon={faFilter} className="mr-2"/> Filtres
                            </div>
                            <ul tabIndex={0}
                                className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
                                <li className="form-control">
                                    <label className="label cursor-pointer">
                                        <span className="">Dealer</span>
                                        <input type="checkbox" className="checkbox"
                                               checked={filterCategory === 'dealer'}
                                               onChange={() => applyCategoryFilter('dealer')}/>

                                    </label>
                                </li>
                                <li className="form-control">
                                    <label className="label cursor-pointer">
                                        <span className="label-text">Trade</span>
                                        <input type="checkbox" className="checkbox"
                                               checked={filterCategory === 'trader'}
                                               onChange={() => applyCategoryFilter('trader')}/>

                                    </label>
                                </li>
                                <li>
                                    <button
                                        className="btn btn-sm bg-neutral hover:bg-accent-content text-white mt-2"
                                        onClick={clearCategoryFilter}>
                                        Effacer les filtres
                                    </button>
                                </li>
                            </ul>


                        </div>
                        <div className="btn btn-primary btn-sm float-right "
                             onClick={() => handleNewClick(changelogs)}>
                            <FontAwesomeIcon icon={faPlus}/> Nouveau changelog
                        </div>
                    </div>
                </div>

                {/*<div className="divider mt-2"></div>*/}

                {/*TABLE*/}
                <div className="h-full w-full pb-6 bg-base-100">
                    <div className="overflow-x-auto">
                        <table className="table table-xs table-pin-rows w-full">
                            <thead>
                            <tr>
                                <th>Version</th>
                                <th>Catégorie</th>
                                <th onClick={() => {
                                    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
                                }} style={{cursor: 'pointer'}}>
                                    Release Date <FontAwesomeIcon
                                    icon={sortOrder === 'asc' ? faArrowUpShortWide : faArrowDownShortWide}/>
                                </th>
                                <th>Status</th>

                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {isLoading && (
                                <tr>
                                    <td  className="text-center w-10 py-3">
                                        <div className="skeleton h-4 w-full"></div>
                                    </td>
                                    <td className="text-center">
                                        <div className="skeleton h-4 w-full"></div>
                                    </td>
                                    <td className="text-center">
                                        <div className="skeleton h-4 w-full"></div>
                                    </td>
                                    <td className="text-center">
                                        <div className="skeleton h-4 w-full"></div>
                                    </td>
                                    <td className="text-center" colSpan={2}>
                                        <div className="skeleton h-4 w-full"></div>
                                    </td>
                                </tr>
                            )}
                            {error && (
                                <tr>
                                    <td colSpan="4" className="text-center">
                                        <div role="alert" className="alert alert-error">
                                            <p>Error loading changelogs: {error.message}</p>
                                        </div>
                                    </td>
                                </tr>
                            )}
                            {filteredChangelogs.map(changelog => (
                                <tr key={changelog.id} className="hover">
                                    <td>
                                        {changelog.version}
                                    </td>
                                    <td className="capitalize">
                                        {changelog.category}
                                    </td>
                                    <td>{formatDate(changelog.date)}</td>
                                    <td className="capitalize">
                                       <span className={`${getStatusColorClass(changelog.status)}`}>
                                            {changelog.status}
                                        </span>
                                    </td>
                                    <td className="text-right ">
                                        <div className="flex justify-end">
                                            <div onClick={() => handleEditClick(changelog)}
                                                 className="btn btn-square btn-ghost lg:mr-2"
                                            >
                                                <FontAwesomeIcon icon={faEdit} className="h-5 w-5"/>
                                            </div>
                                            <button className="btn btn-square btn-ghost "
                                                    onClick={() => {
                                                        setChangelogToDelete(changelog);
                                                        setShowConfirmation(true);
                                                        document.getElementById('confirmation_modal').showModal();
                                                    }}
                                            >
                                                <FontAwesomeIcon icon={faTrash} className="h-5 w-5"/>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Content>

        </div>
    );
});

export default Changelogs;
