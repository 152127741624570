import Axios from "@/_services/caller.service";

let getQueries = async (requestData) => {

    const { data } = await Axios.post('/system/install', requestData, {
        headers: {
            'X-AUTH-TOKEN': '6a6047295b95dbcd5830a66c49e4ce56ba3e4e91cf8a39ad891c4babe845e6ab',
        }
    });
    return data;
}

let getCoordinates = async (address) => {
    try {
        // Construction de l'objet de données clé-valeur
        let newData = {
            address: address
        };

        console.log(newData)
        // Envoi de la requête POST avec les données clé-valeur
        const { data } = await Axios.post('/system/getCoordinates', newData, {
            headers: {
                'X-AUTH-TOKEN': '6a6047295b95dbcd5830a66c49e4ce56ba3e4e91cf8a39ad891c4babe845e6ab',
            }
        });
        return data;
    } catch (error) {
        console.error('Error fetching coordinates:', error.message);
    }
}


export const installationService = {
    getQueries, getCoordinates
}