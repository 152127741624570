import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import { communicationsService } from '@/_services';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TextEditor from '@/_utils/TextEditor';
import Toast from '@/components/Toast';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCommentLines, faEye, faPencil} from "@fortawesome/pro-light-svg-icons";
import Dropdown from "@/components/Dropdown";
import Checkbox from "@/components/Checkbox";
import {useData} from "@/_helpers/Context";

const CommunicationEdit = () => {

    const { state } = useLocation();
    const communication = state?.communication;

    const { data, setSpecificData } = useData();
    const { communications } = data;
    const { title, date_starting, date_ending, status, category, contents } = communication;
    const { fr, en, nl } = contents;
    const navigate = useNavigate();
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [previewContent, setPreviewContent] = useState("");
    const [subject, setSubject] = useState('');
    const [tags, setTags] = useState('');
    const [tagsList, setTagsList] = useState('');
    const [countriesList, setCountriesList] = useState('');
    const [filterTags, setFilterTags] = useState('');
    const [isLoading, setLoading] = useState(true);


    const [formData, setFormData] = useState({
        id: communication.id,
        title: title,
        category: category,
        date_starting: new Date(date_starting),
        date_ending: new Date(date_ending),
        status: status,
        contents: {
            fr: { title: fr.title, content: fr.content },
            en: { title: en.title, content: en.content },
            nl: { title: nl.title, content: nl.content }
        },
        languages: communication.languages,
        users_status: communication.users_status,
        countries: communication.countries,
        tags: communication.tags,
        contrat: communication.contrat
    });

    const [selectedOptions, setSelectedOptions] = useState({
        languages: formData.languages || [],
        countries: formData.countries || [],
        status_user: formData.users_status || [],
        tags: formData.tags ? formData.tags : [],
        contrat: formData.contrat ? formData.contrat : []
    });


    const [editors, setEditors] = useState({
        fr: communication?.contents?.fr?.content || '',
        nl: communication?.contents?.nl?.content || '',
        en: communication?.contents?.en?.content || '',
    });

    const [dateStarting, setDateStarting] = useState(new Date(formData.date_starting));
    const [dateEnding, setDateEnding] = useState(new Date(formData.date_ending));

    const handleOptionToggle = (category, value) => {
        setSelectedOptions(prevOptions => {
            const updatedOptions = { ...prevOptions };
            if (!updatedOptions[category]) {
                updatedOptions[category] = [];
            }

            // Vérifier si la valeur est déjà sélectionnée
            const valueIndex = updatedOptions[category].indexOf(value);
            if (valueIndex !== -1) {
                // Si la valeur est déjà sélectionnée, la décocher
                updatedOptions[category].splice(valueIndex, 1);
            } else {
                // Sinon, la sélectionner
                updatedOptions[category].push(value);
            }

            // Vérifier si toutes les options de la catégorie sont décochées, et si oui, supprimer la clé
            if (updatedOptions[category] && updatedOptions[category].length === 0) {
                delete updatedOptions[category];
            }

            // Mettre à jour le formData avec les options sélectionnées
            setFormData(prevFormData => {
                const updatedFormData = { ...prevFormData };
                updatedFormData[category] = updatedOptions[category] || []; // Mettre à jour la catégorie spécifique dans formData
                return updatedFormData;
            });

            return { ...updatedOptions };
        });
    };



    const handleEditorChange = (lang, content) => {
        setEditors(prevEditors => ({
            ...prevEditors,
            [lang]: content,
        }));
        setFormData(prevFormData => ({
            ...prevFormData,
            contents: {
                ...prevFormData.contents,
                [lang]: {
                    ...prevFormData.contents[lang],
                    content: `<div id="editor-${lang}">`+content+`</div>`,
                },
            },
        }));
    };
    const handleTitleChange = (lang, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            contents: {
                ...prevFormData.contents,
                [lang]: {
                    ...prevFormData.contents[lang],
                    title: value,
                },
            },
        }));
    };

    const handleSubmit = async e => {
        setLoading(true);

        e.preventDefault();
        try {
            if (formData.status === 'online') {
                const onlineCommunicationExists = await communicationsService.checkOnlineCommunicationExists(
                    formData.category
                );
                if (onlineCommunicationExists) {
                    const onlineCommunication = await communicationsService.getOnlineCommunication(
                        formData.category
                    );
                    await communicationsService.updateCommunication(onlineCommunication.id, {
                        ...onlineCommunication,
                        status: 'archived'
                    });
                }
            }

            // Inclure les valeurs de dateStarting et dateEnding dans formData
            const updatedFormData = {
                ...formData,
                date_starting: dateStarting,
                date_ending: dateEnding
            };

            await communicationsService.updateCommunication(communication.id, updatedFormData);
            const updatedCommunications = data.communications.map((comm) => {
                if (comm.id === communication.id) {
                    return updatedFormData;
                } else {
                    return comm;
                }
            });
            console.log("submit updatedCommunications", updatedCommunications)
            //Mise à jour de la liste des communications
            setSpecificData('communications', updatedCommunications);


            setLoading(false);
            setSuccessMessage('Enregistrement réussi!');

            setTimeout(() => {
                navigate('../index');
            }, 1000);
        } catch (error) {
            setLoading(false);
            console.error(error);
            setErrorMessage(`Une erreur s'est produite lors de l'enregistrement: ${error.message}`);
        }
    };

    const handlePreview = (content) => {
        setPreviewContent(content);
        document.getElementById(`modal-preview`).showModal();
    };

    useEffect(() => {

        const fetchData = async () => {
            try {

                const dataTags = await communicationsService.getAllTags();
                const dataCountries = await communicationsService.getAllCountries();
                const tagsArray = Object.values(dataTags);
                const countriesArray = Object.values(dataCountries);
                setCountriesList(countriesArray);
                setTagsList(tagsArray);

                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };
        fetchData();
    }, []);


    return (
        <div>
            {isLoading && (
                <div className="text-center h-full align-middle justify-center">
                    <span className="loading loading-spinner loading-lg"></span>
                </div>
            )}

            {!isLoading && (
                <>
                <form onSubmit={handleSubmit}>
                    <div className="card w-full overflow-auto bg-base-100 rounded-xl shadow-md  h-[87vh] mb-5">

                        {/* VERSION DATE STATUS */}
                        <div className="flex lg:justify-between  lg:items-center items-start p-3 lg:flex-row flex-col ">
                                <div className="text-sm breadcrumbs">
                                    <ul>
                                        <li><Link to="/admin/communications/index">
                                            <FontAwesomeIcon icon={faCommentLines}  className="text-primary mr-3"/> Communications</Link>
                                        </li>
                                        <li><FontAwesomeIcon icon={faPencil} className="text-primary mr-3"/> Edition</li>
                                    </ul>
                                </div>
                                <button type="submit" className="btn btn-secondary btn-sm hidden lg:inline-flex ">
                                    Enregistrer
                                </button>
                            </div>
                        <div className="px-3">
                            <div className="flex justify-between align-middle mb-3">
                                <h1 className="lg:text-3xl font-bold m-0 p-0 leading-normal">
                                    Modification de la communication <i>{title}</i>
                                </h1>
                            </div>
                            <div className="flex lg:items-center lg:flex-row flex-col items-start gap-2">
                                <div className="flex-grow w-full">
                                    <div className="form-control mb-2">
                                        <label htmlFor="subject" className="label">
                                            Titre:
                                        </label>
                                        <input
                                            type="text"
                                            id="title"
                                            className="input input-bordered"
                                            value={formData.title || ''}
                                            onChange={(e) => setFormData({...formData, title: e.target.value})}
                                        />
                                    </div>
                                </div>

                                <div className="flex-grow  w-full">
                                    <div className="form-control mb-2">
                                        <label htmlFor="releaseDate" className="label">
                                            Date de début:
                                        </label>
                                        <DatePicker
                                            selected={dateStarting}
                                            dateFormat="dd/MM/yyyy"
                                            className="input input-bordered w-full"
                                            onChange={(date) => setDateStarting(date)}
                                        />
                                    </div>
                                </div> <div className="flex-grow  w-full">
                                <div className="form-control mb-2">
                                    <label htmlFor="releaseDate" className="label">
                                        Date de fin:
                                    </label>
                                    <DatePicker
                                        selected={dateEnding}
                                        dateFormat="dd/MM/yyyy"
                                        className="input input-bordered w-full"
                                        onChange={(date) => setDateEnding(date)}
                                    />
                                </div>
                            </div>
                                <div className="flex-grow w-full ">
                                    <div className="form-control mb-2">
                                        <label htmlFor="status" className="label">
                                            Status:
                                        </label>
                                        <select
                                            id="status"
                                            className="select select-bordered"
                                            value={formData.status}
                                            onChange={(e) => setFormData({...formData, status: e.target.value})}
                                        >
                                            <option value="online">✅&nbsp; Online</option>
                                            <option value="draft">✏️&nbsp; Draft</option>
                                            <option value="archived">🗑️ &nbsp; Archived</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="flex-grow w-full ">
                                    <div className="form-control mb-2">
                                        <label htmlFor="status" className="label">
                                            Catégorie:
                                        </label>
                                        <select
                                            id="category"
                                            className="select select-bordered"
                                            value={formData.category}
                                            onChange={(e) => setFormData({...formData, category: e.target.value})}
                                        >
                                            <option value="dealer">Dealer</option>
                                            <option value="trader">Trader</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-4 flex items-center flex-wrap gap-2 ">
                                <Dropdown title="🗣️ &nbsp; Langues" style="input-bordered">
                                    <Checkbox
                                        label="🇫🇷 &nbsp; Français"
                                        checked={selectedOptions["languages"]?.includes("fr") || false}
                                        onChange={() => handleOptionToggle("languages","fr")}
                                    />
                                    <Checkbox
                                        label="🇳🇱 &nbsp; Néerlandais"
                                        checked={selectedOptions["languages"]?.includes("nl") || false}
                                        onChange={() => handleOptionToggle("languages","nl")}
                                    />
                                    <Checkbox
                                        label="🇬🇧 &nbsp; Anglais"
                                        checked={selectedOptions["languages"]?.includes("en") || false}
                                        onChange={() => handleOptionToggle("languages","en")}
                                    />
                                    <Checkbox
                                        label="🇩🇪 &nbsp; Allemand"
                                        checked={selectedOptions["languages"]?.includes("de") || false}
                                        onChange={() => handleOptionToggle("languages","de")}
                                    />

                                </Dropdown>


                                {countriesList && countriesList.length > 0 && (
                                    <Dropdown title="🌍 &nbsp;  Pays" style="input-bordered">
                                        {countriesList.map(country => (
                                            <Checkbox
                                                key={country.id}
                                                label={<><span>{country.flag}</span>&nbsp;{country.name}</>}
                                                checked={selectedOptions["countries"]?.includes(country.id) || false}
                                                onChange={() => handleOptionToggle("countries", country.id)}
                                            />
                                        ))}
                                    </Dropdown>
                                )}


                                <Dropdown title="🧑‍💼 &nbsp; Status utilisateur" style="input-bordered">
                                    {formData.category === 'dealer' && (
                                        <>
                                            <Checkbox
                                                label="Vendeur"
                                                checked={selectedOptions["status_user"]?.includes(6) || false}
                                                onChange={() => handleOptionToggle("status_user", 6)}
                                            />
                                            <Checkbox
                                                label="Modérateur"
                                                checked={selectedOptions["status_user"]?.includes(8) || false}
                                                onChange={() => handleOptionToggle("status_user", 8)}
                                            />
                                            <Checkbox
                                                label="SAV"
                                                checked={selectedOptions["status_user"]?.includes(5) || false}
                                                onChange={() => handleOptionToggle("status_user", 5)}
                                            />
                                            <Checkbox
                                                label="Support"
                                                checked={selectedOptions["status_user"]?.includes(18) || false}
                                                onChange={() => handleOptionToggle("status_user", 18)}
                                            />
                                            <Checkbox
                                                label="Direction"
                                                checked={selectedOptions["status_user"]?.includes(10) || false}
                                                onChange={() => handleOptionToggle("status_user", 10)}
                                            />
                                            <Checkbox
                                                label="Admin"
                                                checked={selectedOptions["status_user"]?.includes(19) || false}
                                                onChange={() => handleOptionToggle("status_user", 19)}
                                            />
                                            <Checkbox
                                                label="Super-Admin"
                                                checked={selectedOptions["status_user"]?.includes(20) || false}
                                                onChange={() => handleOptionToggle("status_user", 20)}
                                            />
                                        </>
                                    )}
                                    {formData.category === 'trader' && (
                                        <>
                                            <Checkbox
                                                label="User"
                                                checked={selectedOptions["status_user"]?.includes("user") || false}
                                                onChange={() => handleOptionToggle("status_user", "user")}
                                            />
                                            <Checkbox
                                                label="Admin"
                                                checked={selectedOptions["status_user"]?.includes("admin") || false}
                                                onChange={() => handleOptionToggle("status_user", "admin")}
                                            />
                                        </>
                                    )}

                                </Dropdown>

                                {/*tagsList && tagsList.length > 0 && (
                                    <Dropdown title="🔖  &nbsp; Tags" style="input-bordered ">
                                        <input
                                            type="text"
                                            className="input input-bordered input-sm w-full max-w-xs my-3"
                                            placeholder="Rechercher un tag..."
                                            value={filterTags}
                                            onChange={(e) => setFilterTags(e.target.value)}
                                        />
                                        {tagsList
                                            .filter(tag => tag.name.toLowerCase().includes(filterTags.toLowerCase()))
                                            .map(tag => (
                                                <div key={tag.id}>
                                                    <li key={tag.id}>
                                                        <Checkbox
                                                            className={"capitalize"}
                                                            label={tag.name}
                                                            checked={selectedOptions["tags"]?.includes(tag.id) || false}
                                                            onChange={() => handleOptionToggle("tags", tag.id)}
                                                        />
                                                    </li>
                                                </div>
                                            ))}
                                    </Dropdown>
                                )*/}
                                {formData.category === 'trader' && (

                                <Dropdown title="📑 &nbsp; Type de contrat" style="input-bordered">
                                    <Checkbox
                                        label="Lite"
                                        checked={selectedOptions["contrat"]?.includes("lite") || false}
                                        onChange={() => handleOptionToggle("contrat","lite")}
                                    />
                                    <Checkbox
                                        label="Premium"
                                        checked={selectedOptions["contrat"]?.includes("premium") || false}
                                        onChange={() => handleOptionToggle("contrat","premium")}
                                    />
                                </Dropdown>
                                )}

                            </div>
                        </div>


                        <div className="p-3">


                        {/* DETAILS */}
                        {Object.entries(editors).map(([lang, content]) => (
                            <div className="lg:pl-16 pl-0" key={lang}>
                                <div className="card w-full p-3 lg:mt-2 mb-16 lg:pb-5 pb-10">
                                    <div className="title-lang lg:inline-flex hidden lg:justify-center" style={{width: '390px',left: '-217px',top: '174px'}}>
                                        <div className="text-3xl font-bold">
                                            {lang === 'fr' ? '🇫🇷 Français' : lang === 'nl' ? '🇳🇱 Néerlandais' : '🇬🇧 Anglais'}
                                        </div>
                                    </div>
                                    <div className=" inline-flex lg:hidden">
                                        <h1 className="text-2xl mb-4">{lang === 'fr' ? '🇫🇷 Français' : lang === 'nl' ? '🇳🇱 Néerlandais' : '🇬🇧 Anglais'}</h1>
                                    </div>
                                    <div className="btn btn-sm btn-ghost ml-4" style={{position: 'absolute', right: '18px',top: '18px'}} onClick={() => handlePreview(formData.contents[lang])} >
                                        <FontAwesomeIcon icon={faEye} /> Preview
                                    </div>
                                    <div className={"title-input"}>
                                        <input
                                            type="text"
                                            id={`title-${lang}`}
                                            style={{borderRadius:'0px 10px 0px 0px', marginBottom:'-1px'}}
                                            className="input input-bordered w-full mb-5"
                                            placeholder={`Titre ${lang}`}
                                            value={formData.contents[lang].title}
                                            onChange={(e) => handleTitleChange(lang, e.target.value)}
                                        />
                                    </div>

                                    <TextEditor
                                        key={lang}
                                        style={{height: '300px', marginBottom : '30px'}}
                                        lang={lang}
                                        content={content}
                                        handleEditorChange={handleEditorChange}
                                    />


                                </div>
                            </div>
                        ))}
                        </div>
                        <button type="submit" className="btn btn-secondary mt-10 lg:hidden inline-flex w-full ">
                            Enregistrer
                        </button>

                </div>
                </form>
                    <dialog id={`modal-preview`} className="modal modal-communication">
                <div className="modal-box p-0">
                    <div className="modal-header">
                        <button onClick={() => document.getElementById(`modal-preview`).close()} className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 z-50">✕</button>

                        <h5 dangerouslySetInnerHTML={{ __html: previewContent.title }}></h5>
                        <div className="overlay"></div>
                        <div className="overlay-europe"></div>
                    </div>
                    <div className="modal-body">
                        <div className="preview-content communications" dangerouslySetInnerHTML={{ __html: previewContent.content }}></div>
                    </div>
                    <div className="modal-footer d-flex align-items-center justify-content-end mt-2">
                        <div onClick={() => document.getElementById(`modal-preview`).close()} className="btn btn-danger modal-close-btn btn-block">
                            J'ai compris - continuer
                        </div>
                    </div>

                </div>
                <form method="dialog" className="modal-backdrop">
                    <button onClick={() => document.getElementById(`modal-preview`).close()}>close</button>
                </form>
            </dialog>
                </>
            )}

            {successMessage && (
                <Toast toastClass="success" text={successMessage} />
            )}
            {errorMessage && (
                <Toast toastClass="error" text={errorMessage} />
            )}

        </div>
    );
};

export default CommunicationEdit;
