// Stat.js
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Stat = ({ icon, title, value }) => {
    return (
        <div className="stats shadow">
            <div className="stat">
                <div className="stat-figure dark:text-slate-300 text-primary">
                    <FontAwesomeIcon icon={icon} className="h-8 w-8" />
                </div>
                <div className="stat-title dark:text-slate-300">{title}</div>
                <div className="stat-value dark:text-slate-300 text-primary">{value}</div>
                <div className="stat-desc underline-offset-1"></div>
            </div>
        </div>
    );
};

export default Stat;
