// TextEditor.js
import React from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const TextEditor = ({ lang, content, handleEditorChange, style }) => {


    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'script': 'super' }, { 'script': 'sub' }],
            [{ 'header': '1' }, { 'header': '2' }, 'blockquote', 'code-block'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }, { 'align': [] }],
            ['link', 'image', 'video'],
            ['clean'],
        ],
    };


    return (
        <>
            <ReactQuill
                theme="snow"
                style={style}
                value={content}
                modules={modules}
                onChange={(value) => handleEditorChange(lang, value)}
            />
        </>
    );
};

export default TextEditor;
