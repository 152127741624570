//caller.service.js

import axios from "axios";
import {accountService} from "./account.service";
import Logger from '../_utils/Logger.js';

const Axios = axios.create({
     //baseURL: 'https://5g18qllika.sharedwithexpose.com/api/',
     baseURL: 'https://core.fstbckgt.com/api/',
})


// Intercepteur pour le token
Axios.interceptors.request.use(request => {
    if (accountService.isLogged()) {
        request.headers['Authorization'] = 'Bearer ' + accountService.getToken()
    }
    return request
})
Axios.interceptors.response.use(response => {
    return response;
},  error => {
    Logger('error', error.message);

    if (error.response && error.response.status === 401 && accountService.isLogged()) {
        try {
            const response =  accountService.getNewRefreshToken();
            console.log('Nouveau jeton rafraîchi')
            const newRefreshToken = response.data.refresh_token;
            const newToken = response.data.token;
            accountService.saveRefreshToken(newRefreshToken);
            accountService.saveToken(newToken);
            console.log("refresh_token", response.data.refresh_token);
            console.log("token", response.data.token);

            const originalRequest = error.config;
            originalRequest.headers.Authorization = 'Bearer ' + newToken;
            return Axios(originalRequest);
        } catch (refreshError) {
            console.log('originalRequest', error.config);
           /* if (window.confirm('Caller service : Votre session a expiré, voulez-vous vous reconnecter ?')) {*/
                accountService.logout();
                window.location.href = '/login';
                return Promise.reject(error);

            /*}else {
                console.log('Caller service : NOPE ERROR originalRequest: ', error.config);

                setTimeout(() => {
                        return Promise.reject(error);
                    }
                , 10000);

            }*/
        }
    }
    console.log(error.response.status);
    return Promise.reject(error);
});




export default Axios;