// Dropdown.js
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/pro-regular-svg-icons";

function Dropdown({title, children, style}) {
    return (
        <div className="dropdown dropdown-bottom lg:w-auto w-full">
            <div tabIndex={0} role="button" className={`mr-5 btn bg-base-100 ${style} w-full justify-between`}>
                {title}
                <FontAwesomeIcon icon={faChevronDown} className="ml-5" />
            </div>
            <div tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-72">
                <div className="overflow-y-auto max-h-96 p-3">
                    {children}
                </div>
            </div>
        </div>
    );
}

export default Dropdown;
