import React, { useEffect, useState, createContext, useContext } from 'react';
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCars, faChevronDown, faCommentLines, faHome, faNotes, faScrewdriverWrench, faUsers, faUserSecret } from "@fortawesome/pro-light-svg-icons";
import { userService } from "../../_services";

// Créez un contexte pour les informations de l'utilisateur connecté
const UserContext = createContext();

// Créez un Hook personnalisé pour récupérer les informations de l'utilisateur
const useCurrentUser = () => {
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        const fetchCurrentUser = async () => {
            const user = await userService.getCurrentUser();
            setCurrentUser(user.data);
        };
        fetchCurrentUser();
    }, []);

    return currentUser;
};

const SideMenu = () => {
    // Utilisez le Hook personnalisé pour récupérer les informations de l'utilisateur
    const currentUser = useCurrentUser();

    return (
        <UserContext.Provider value={currentUser}>
            <div className="drawer-side">
                <label htmlFor="left-sidebar-drawer" aria-label="close sidebar" className="drawer-overlay"></label>

                <ul className="menu  bg-base-100 text-base-content min-h-full w-80 p-4 pl-0  z-50">
                    <li className="mb-2 font-semibold text-xl">
                        <Link to={'/admin/dashboard'} className="logoFB">
                            Fastback<br/>
                            Core
                        </Link>
                    </li>
                    <li>
                        <NavLink
                            to={"/admin/dashboard"}
                            className={({ isActive }) => `${isActive ? 'active' : ''}`}>
                            <FontAwesomeIcon icon={faHome} /> Dashboard
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={"/admin/installation"}
                            className={({ isActive }) => `${isActive ? 'active' : ''}`}>
                            <FontAwesomeIcon icon={faScrewdriverWrench} /> Installation
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={"/admin/changelogs/index"}
                            className={({ isActive }) => `${isActive ? 'active' : ''}`}>
                            <FontAwesomeIcon icon={faNotes} /> Changelogs
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={"/admin/communications/index"}
                            className={({ isActive }) => `${isActive ? 'active' : ''}`}>
                            <FontAwesomeIcon icon={faCommentLines} /> Communications
                        </NavLink>
                    </li>
                    {currentUser && currentUser.roles && currentUser.roles.includes("ROLE_ADMIN") && (
                        <li>
                            <NavLink
                                to={"/admin/users/index"}
                                className={({ isActive }) => `${isActive ? 'active' : ''}`}>
                                <FontAwesomeIcon icon={faUsers} /> Users
                            </NavLink>
                        </li>
                    )}
                    <li>
                        <NavLink
                            to={"/admin/merchants/index"}
                            className={({ isActive }) => `${isActive ? 'active' : ''}`}>
                            <FontAwesomeIcon icon={faCars} /> Merchants
                        </NavLink>
                    </li>
                </ul>
            </div>
        </UserContext.Provider>
    );
};

export default SideMenu;
