// DataProvider.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import { userService } from '@/_services/users.service';
import { dashboardService } from '@/_services/dashboard.service';
import { communicationsService } from '@/_services/communications.service'; // Ajoutez les services nécessaires
import { changelogsService } from '@/_services/changelogs.service';

const DataContext = createContext();

const DataProvider = ({ children }) => {
    const [data, setData] = useState({
        currentUser: {},
        dashboardStats: {
            acceptance: '',
            carCount: '',
            lightMerchantCount: '',
            premiumMerchantCount: '',
            production: '',
            totalMerchantCount: '',
        },
        users: [],
        communications: [],
        changelogs: [],
    });

    // Récupérer les données de l'utilisateur actuel
    useEffect(() => {
        userService.getCurrentUser()
            .then(res => {
                setData(prevData => ({
                    ...prevData,
                    currentUser: res.data
                }));
            })
            .catch(error => console.error('Error fetching current user:', error));
    }, []);

    // Récupérer les statistiques du tableau de bord
    useEffect(() => {
        dashboardService.getAllStats()
            .then(res => {
                setData(prevData => ({
                    ...prevData,
                    dashboardStats: {
                        acceptance: res.acceptance,
                        carCount: res.carCount,
                        lightMerchantCount: res.lightMerchantCount,
                        premiumMerchantCount: res.premiumMerchantCount,
                        production: res.production,
                        totalMerchantCount: res.totalMerchantCount,
                    }
                }));
            })
            .catch(error => console.error('Error fetching dashboard stats:', error));
    }, []);



    // Récupérer les communications
    useEffect(() => {
        communicationsService.getAllCommunications()
            .then(res => {
                setData(prevData => ({
                    ...prevData,
                    communications: res
                }));
            })
            .catch(error => console.error('Error fetching communications:', error));
    }, []);

    //Récupérer les users
    useEffect(() => {
        userService.getAllUsers()
            .then(res => {
                setData(prevData => ({
                    ...prevData,
                    users: res
                }));
            })
            .catch(error => console.error('Error fetching users:', error));
    }, []);

    // Récupérer les changelogs
    useEffect(() => {
        changelogsService.getAllChangelogs()
            .then(res => {
                setData(prevData => ({
                    ...prevData,
                    changelogs: res
                }));
            })
            .catch(error => console.error('Error fetching changelogs:', error));
    }, []);

    // Fonction pour définir des données spécifiques
    const setSpecificData = (dataType, newData) => {
        setData(prevData => ({ ...prevData, [dataType]: newData }));
    };

    return (
        <DataContext.Provider value={{ data, setSpecificData }}>
            {children}
        </DataContext.Provider>
    );
};

export const useData = () => {
    return useContext(DataContext);
};

export default DataProvider;
