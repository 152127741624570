//AuthGuard.js

import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { accountService } from "@/_services/account.service";
import { userService } from "@/_services/users.service";

const AuthGuard = ({ children }) => {

    useEffect(() => {
        const checkAuth = async () => {
            if (accountService.isLogged()) {
                const token = accountService.getToken();
                try {
                     await userService.getCurrentUser(token);
                } catch (error) {
                    console.error("Erreur lors de la récupération des informations de l'utilisateur actuel:", error.message);
                    accountService.logout();
                    return;
                }
            }
        };
        checkAuth();
    }, []);


    if (!accountService.isLogged()) {
        return <Navigate to="/" />;
    }

    return children;
};

export default AuthGuard;
