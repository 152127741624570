import React from 'react';

const Merchants = () => {
    return (
        <div className="User">
            Merchants list
        </div>
    );
};

export default Merchants;