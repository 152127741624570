// Checkbox.js
import React from 'react';

function Checkbox({ label, checked, onChange, className }) {
    return (
        <label className="label cursor-pointer">
            <span className={`${className}`}>{label}</span>
            <input
                type="checkbox"
                className="checkbox"
                checked={checked}
                onChange={onChange}
            />
        </label>
    );
}

export default Checkbox;
