import React, {useRef} from 'react';
import {Outlet} from "react-router-dom";
import Header from "@/components/admin/Header";
import SideMenu from "@/components/admin/SideMenu";

const LayoutAdmin = () => {

    const mainContentRef = useRef(null);

    return (
            <div className="drawer lg:drawer-open ">
                <input id="left-sidebar-drawer" type="checkbox" className="drawer-toggle" />
                <div className="drawer-content flex flex-col">
                    <Header/>
                    <main className="flex-1 overflow-y-auto pt-8 px-6 pl-100 bg-base-200" ref={mainContentRef}>
                        <Outlet/>
                    </main>
                    {/*<label htmlFor="my-drawer-2" className="btn btn-primary drawer-button lg:hidden">Menu</label>*/}
                </div>
                <SideMenu/>
            </div>
    );
};

export default LayoutAdmin;