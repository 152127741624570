import React, { useState } from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import { changelogsService } from '@/_services';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TextEditor from '@/_utils/TextEditor';
import Toast from '@/components/Toast';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faNotes, faPencil} from "@fortawesome/pro-light-svg-icons";
import { formatFrenchDate } from '@/_utils/DateUtils';
import Breadcrumb from '@/components/admin/Breadcrumbs';


const ChangelogAdd = () => {

    const { state } = useLocation();
    console.log(state);
    const changelogs = state?.changelogs;
    const nextId = changelogs.reduce((maxId, changelog) => Math.max(maxId, changelog.id), 0) + 1;
    const navigate = useNavigate();
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [previewContent, setPreviewContent] = useState(""); // État pour stocker le contenu de prévisualisation






    const [formData, setFormData] = useState({
        id: '',
        version: '',
        category: '',
        date: new Date(),
        status: 'draft',
        details: {
            fr: '',
            nl: '',
            en: '',
        },
    });


    // État pour les champs non affectés par la réinitialisation des éditeurs
    const [version, setVersion] = useState('');
    const [releaseDate, setReleaseDate] = useState(new Date());
    const [status, setStatus] = useState('draft');
    const [category, setCategory] = useState('dealer');

    const formattedDate = formatFrenchDate(formData.date);

    const handleEditorChange = (lang, content) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            id: nextId,
            version: version,
            category: category,
            date: releaseDate,
            status: status,
            details: {
                ...prevFormData.details,
                [lang]: content,
            },
        }));


    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            if(formData.status === 'online')  {
                // Vérifier si un changelog en ligne existe pour la catégorie sélectionnée
                const onlineChangelogExists = changelogs.some(changelog => changelog.status === 'online' && changelog.category === formData.category);

                // Si un changelog en ligne existe, le passer en statut "archived"
                if (onlineChangelogExists) {
                    const onlineChangelog = changelogs.find(changelog => changelog.status === 'online' && changelog.category === formData.category);
                    await changelogsService.updateChangelog(onlineChangelog.id, { ...onlineChangelog, status: 'archived' });
                }
            }

            // Enregistrer le nouveau changelog avec le statut approprié
            const formattedDate = releaseDate.toISOString().split('T')[0];
            const formattedFormData = {
                id: nextId,
                version: version,
                category: category,
                date: formattedDate,
                status: formData.status,
                details: formData.details,
            };

            await changelogsService.addChangelog(formattedFormData);
            console.log(formattedFormData);
            setSuccessMessage('Enregistrement réussi!');
            setTimeout(() => {
                navigate('../index');
            }, 3000);


        } catch (error) {
            console.log(formData)
            console.error(error);
            setErrorMessage('Une erreur s\'est produite lors de l\'enregistrement: ' + error.message);
        }
    };


    const handlePreview = (content) => {
        // Mettre à jour le contenu de prévisualisation avec le contenu du TextEditor correspondant à la langue sélectionnée
        setPreviewContent(content);
        // Afficher le modal
        document.getElementById(`modal-preview`).showModal();
    };
    return (
        <div>
            <form onSubmit={handleSubmit}>
                {/* VERSION DATE STATUS */}
                <div className="card w-full overflow-auto  bg-base-100 rounded-xl shadow-md h-[90vh] mb-10 ">
                    <div className="p-3">
                        <div className="flex lg:justify-between  lg:items-center items-start p-3 lg:flex-row flex-col ">

                            <Breadcrumb
                                firstItem={{ text: 'Changelogs', link: '/admin/changelogs/index', icon: faNotes }}
                                secondItem={{ text: 'Nouveau', icon: faPencil }}
                            />

                            <button type="submit" className="btn btn-secondary btn-sm hidden lg:inline-flex ">
                                Enregistrer
                            </button>
                        </div>

                        <div className="flex items-center flex-wrap">
                            <div className="flex-grow pr-4 mr-4">
                                <div className="form-control mb-2">
                                    <label htmlFor="version" className="label">
                                        Version:
                                    </label>
                                    <input
                                        type="text"
                                        id="version"
                                        className="input input-bordered"
                                        value={version}
                                        onChange={(e) => setVersion(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="flex-grow pr-4">
                                <div className="form-control mb-2">
                                    <label htmlFor="releaseDate" className="label">
                                        Release Date:
                                    </label>
                                    <DatePicker
                                        selected={releaseDate}
                                        dateFormat="dd/MM/yyyy"
                                        className="input input-bordered w-full"
                                        onChange={(date) => setReleaseDate(date)}
                                    />
                                </div>
                            </div>
                            <div className="flex-grow pr-4">
                                <div className="form-control mb-2">
                                    <label htmlFor="status" className="label">
                                        Status:
                                    </label>
                                    <select
                                        id="status"
                                        className="select select-bordered"
                                        value={status}
                                        onChange={(e) => setStatus(e.target.value)}
                                    >
                                        {/*<option value="online">✅ Online</option>*/}
                                        <option value="draft">✏️ Draft</option>
                                        {/*<option value="archived">🗑️ Archived</option>*/}
                                    </select>
                                </div>
                            </div>
                            <div className="flex-grow pr-4">
                                <div className="form-control mb-2">
                                    <label htmlFor="status" className="label">
                                        Catégorie:
                                    </label>
                                    <select
                                        id="status"
                                        className="select select-bordered"
                                        value={category}
                                        onChange={(e) => setCategory(e.target.value)}
                                    >
                                        <option value="dealer">Dealer</option>
                                        <option value="trader">Trader</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" p-3">
                        {/* DETAILS */}
                        {['fr', 'nl', 'en'].map((lang) => (
                            <div className="lg:pl-16 pl-0" key={lang}>
                                <div className="card w-full p-3 lg:mt-2 mb-16 lg:pb-5 pb-10">
                                    <div className="title-lang lg:inline-flex hidden lg:justify-center">
                                        <div className="text-3xl font-bold">
                                            {lang === 'fr' ? '🇫🇷 Français' : lang === 'nl' ? '🇳🇱 Néerlandais' : '🇬🇧 Anglais'}
                                        </div>
                                    </div>
                                    <div className=" inline-flex lg:hidden">
                                        <h1 className="text-2xl mb-4">{lang === 'fr' ? '🇫🇷 Français' : lang === 'nl' ? '🇳🇱 Néerlandais' : '🇬🇧 Anglais'}</h1>
                                    </div>
                                    <div className="btn btn-sm btn-ghost ml-4"   style={{position: 'absolute', right: '18px', top: '18px'}} onClick={() => handlePreview(formData.details[lang])} >
                                        <FontAwesomeIcon icon={faEye} /> Preview
                                    </div>
                                        <TextEditor
                                            key={lang}
                                            lang={lang}
                                            style={{height: '300px', marginBottom: '30px'}}
                                            content={formData.details[lang]}
                                            handleEditorChange={handleEditorChange}
                                        />
                                </div>

                            </div>
                        ))}
                    </div>
                    <button type="submit" className="btn btn-secondary mt-10 lg:hidden inline-flex w-full ">
                        Enregistrer
                    </button>
                </div>
            </form>
            <dialog id={`modal-preview`} className="modal modal-changelog">
                <div className="modal-box p-0">
                    <div className="modal-header">
                        <button onClick={() => document.getElementById(`modal-preview`).close()} className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 z-50">✕</button>

                        <span className="badge ltu">{formattedDate}</span>
                        <h5>News &amp; updates</h5>
                        <div className="overlay"></div>
                        <div className="overlay-europe"></div>
                    </div>
                    <div className="modal-body">
                        <div className="preview-content changelogs" dangerouslySetInnerHTML={{ __html: previewContent }}></div>
                    </div>
                    <div className="modal-footer d-flex align-items-center justify-content-end mt-2">
                        <div onClick={() => document.getElementById(`modal-preview`).close()} className="btn btn-danger modal-close-btn btn-block">
                            J'ai compris - continuer
                        </div>
                    </div>

                </div>
                <form method="dialog" className="modal-backdrop">
                    <button onClick={() => document.getElementById(`modal-preview`).close()}>close</button>
                </form>
            </dialog>
            {successMessage && (
                <Toast toastClass="success" text={successMessage} />
            )}
            {errorMessage && (
                <Toast toastClass="danger" text={errorMessage} />
            )}
        </div>
    );
};

export default ChangelogAdd;
