import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { accountService } from "@/_services";
import Alert from "@/components/Alert";
import { faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Login = () => {
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [errorType, setErrorType] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [credentials, setCredentials] = useState({
        username: "",
        password: "",
    });

    // Check if user is already logged in
    useEffect(() => {
        if (accountService.isLogged()) {
            navigate("/admin", { replace: true });
        }
    }, [navigate]);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const onChange = (e) => {
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value,
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        accountService
            .login(credentials)
            .then((res) => {
                accountService.saveToken(res.data.token);
                accountService.saveRefreshToken(res.data.refresh_token);
                setError(null);
                navigate("/admin", { replace: true });
            })
            .catch((error) => {
                if (error.response) {
                    let errorMessage =
                        "Une erreur est survenue lors de la connexion au serveur. Veuillez réessayer plus tard.";
                    let errorType = "error";

                    switch (error.response.status) {
                        case 400:
                            errorMessage = "Invalid username or password";
                            errorType = "warning";
                            break;
                        case 401:
                            errorMessage = "Unauthorized access";
                            break;
                        case 404:
                        case 500:
                            errorMessage = `Error ${error.response.status}: ${error.response.data.title}`;
                            break;
                        default:
                            errorMessage = "An unexpected error occurred.";
                            break;
                    }

                    setError(errorMessage);
                    setErrorType(errorType);
                } else {
                    setError(
                        "Une erreur est survenue lors de la connexion au serveur. Veuillez réessayer plus tard."
                    );
                    setErrorType("error");
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className="min-h-screen bg-base-200 flex items-center">
            <div className="card mx-auto w-full max-w-xl bg-base-100 shadow-xl">
                <div className="p-10">
                    <h1 className={"text-2xl font-bold text-center mb-10"}>
                        Login
                    </h1>
                    <form onSubmit={onSubmit}>
                        <input
                            type="text"
                            name="username"
                            className="input input-bordered w-full mb-5"
                            placeholder="Enter your username"
                            value={credentials.username}
                            onChange={onChange}
                        />
                        <br />
                        <div className="relative">
                            <input
                                className="input input-bordered w-full mb-5"
                                type={showPassword ? "text" : "password"}
                                name="password"
                                placeholder="Enter your password"
                                value={credentials.password}
                                onChange={onChange}
                            />
                            <button
                                type="button"
                                className="absolute right-3 top-3 flex items-center"
                                onClick={togglePasswordVisibility}
                            >
                                <FontAwesomeIcon
                                    icon={showPassword ? faEyeSlash : faEye}
                                    className="text-gray-400 cursor-pointer z-50 h-5"
                                />
                            </button>
                        </div>
                        <br />

                        {/* Affichage conditionnel du bouton de connexion en fonction de l'état de chargement */}
                        <button className="btn btn-primary w-full" disabled={loading}>
                            {loading ? "Loading..." : "Log In"}
                        </button>
                        <Link to="/password-lost" className="text-sm text-primary">
                            Forgot password?
                        </Link>

                        {error && (
                            <div className={"mt-5"}>
                                <Alert type={errorType} message={error} />
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
