// Communication.js

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEdit,
    faTrash,
    faArrowUpShortWide,
    faArrowDownShortWide,
    faFilter,
    faPlus,
    faCommentLines, faNotes
} from "@fortawesome/pro-light-svg-icons";
import Alert from "@/components/Alert";
import { useData } from '@/_helpers/Context';
import { communicationsService } from "@/_services";
import Breadcrumb from '@/components/admin/Breadcrumbs';

const Communication = () => {
    const { data, setSpecificData } = useData();
    const { communications } = data;
    const [sortOrder, setSortOrder] = useState('desc');
    const navigate = useNavigate();
    const [communicationToDelete, setCommunicationToDelete] = useState(null);
    const [filterCategory, setFilterCategory] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);

    useEffect(() => {
        if (communications.length === 0) {
            fetchCommunications();
        }
    }, []);

    const fetchCommunications = async () => {
        try {
            const fetchedCommunications = await communicationsService.getAllCommunications();
            setSpecificData('communications', fetchedCommunications);
        } catch (error) {
            console.error('Error fetching communications:', error);
        }
    };

    const handleEditClick = (communication) => {
        navigate(`/admin/communications/edit/${communication.id}`, { state: { communication } });
    };

    const handleNewClick = () => {
        navigate(`/admin/communications/add`);
    };

    const delCommunication = async (communicationId) => {
        setShowConfirmation(false);
        try {
            await communicationsService.deleteCommunication(communicationId);
            const updatedCommunications = communications.filter(communication => communication.id !== communicationId);
            setSpecificData('communications', updatedCommunications);
        } catch (error) {
            console.error('Error deleting communication:', error);
        }
    };

    const getStatusColorClass = (status) => {
        switch (status) {
            case "online":
                return "inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 dark:bg-teal-950 dark:text-emerald-300 dark:ring-green-900/20";
            case "archived":
                return "inline-flex items-center rounded-full bg-neutral-50 px-2 py-1 text-xs font-medium text-neutral-700 ring-1 ring-inset ring-neutral-600/20 dark:bg-neutral-700 dark:text-neutral-300 dark:ring-neutral-600/20";
            case "draft":
                return "inline-flex items-center rounded-full bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10 dark:bg-blue-950 dark:text-indigo-300 dark:ring-indigo-900/10";
            default:
                return "bg-gray-100 text-gray-700";
        }
    };

    const formatDate = (dateString) => {
        const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const applyCategoryFilter = (category) => {
        setFilterCategory(category);
    };

    const clearCategoryFilter = () => {
        setFilterCategory(null);
    };

    const filteredCommunications = filterCategory ? communications.filter(communication => communication.category === filterCategory) : communications;

    const sortedCommunications = filteredCommunications.sort((a, b) => {
        if (sortOrder === 'asc') {
            return new Date(a.date_starting) - new Date(b.date_starting);
        } else {
            return new Date(b.date_starting) - new Date(a.date_starting);
        }
    });

    return (
        <div className="overflow-x-auto">
            <dialog id="confirmation_modal" className="modal">
                <div className="modal-box">
                    <Alert type={"error"} message={"Voulez-vous vraiment supprimer cette communication?"} />
                    <div className="modal-action">
                        <form method="dialog">
                            <button className="btn btn-primary  mr-2" onClick={() => delCommunication(communicationToDelete.id)}>Oui</button>
                            <button className="btn" onClick={() => setShowConfirmation(false)}>Non</button>
                        </form>
                    </div>
                </div>
            </dialog>

            <div className="card w-full p-3 bg-base-100 rounded-xl shadow-md overflow-hidden h-[87vh] mb-10 ">
                <div className="flex lg:justify-between items-start lg:p-3 lg:flex-row flex-col ">

                    <Breadcrumb
                        firstItem={{ text: 'Communications', link: '/admin/communications/index', icon: faCommentLines }}
                    />


                    <div className="flex align-middle justify-center gap-2">
                        <div className="dropdown lg:dropdown-left dropdown-bottom">
                            <div tabIndex={0} role="button" className={filterCategory === null ? 'btn btn-sm btn-neutral' : 'btn btn-sm btn-secondary text-white'}>
                                <FontAwesomeIcon icon={faFilter} className="lg:mr-2" /> Filtres
                            </div>
                            <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
                                <li className="form-control">
                                    <label className="label cursor-pointer">
                                        <span className="">Dealer</span>
                                        <input type="checkbox" className="checkbox" checked={filterCategory === 'dealer'} onChange={() => applyCategoryFilter('dealer')} />
                                    </label>
                                </li>
                                <li className="form-control">
                                    <label className="label cursor-pointer">
                                        <span className="label-text">Trade</span>
                                        <input type="checkbox" className="checkbox" checked={filterCategory === 'trader'} onChange={() => applyCategoryFilter('trader')} />
                                    </label>
                                </li>
                                <li>
                                    <button className="btn btn-sm bg-neutral hover:bg-accent-content text-white mt-2" onClick={clearCategoryFilter}>
                                        Effacer les filtres
                                    </button>
                                </li>
                            </ul>
                        </div>

                        <div className="btn btn-primary btn-sm float-right mb-10" onClick={handleNewClick}>
                            <FontAwesomeIcon icon={faPlus} /> Nouvelle communication
                        </div>
                    </div>
                </div>

                <div className="h-full w-full pb-6 bg-base-100">
                    <div className="overflow-x-auto">
                        <table className="table table-xs table-pin-rows w-full">
                            <thead>
                            <tr>
                                <th>Titre</th>
                                <th onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')} style={{ cursor: 'pointer' }}>
                                    Date de début <FontAwesomeIcon icon={sortOrder === 'asc' ? faArrowUpShortWide : faArrowDownShortWide} />
                                </th>
                                <th>Date de fin</th>
                                <th>Catégorie</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {sortedCommunications.map(communication => (
                                <tr key={communication.id} className="hover">
                                    <td>{communication.title}</td>
                                    <td>{formatDate(communication.date_starting)}</td>
                                    <td>{formatDate(communication.date_ending)}</td>
                                    <td className="capitalize">{communication.category}</td>
                                    <td className="capitalize">
                                            <span className={`${getStatusColorClass(communication.status)}`}>
                                                {communication.status}
                                            </span>
                                    </td>
                                    <td className="text-right">
                                        <div className="flex justify-end">
                                            <div onClick={() => handleEditClick(communication)} className="btn btn-square btn-ghost lg:mr-2">
                                                <FontAwesomeIcon icon={faEdit} className="h-5 w-5" />
                                            </div>
                                            <button className="btn btn-square btn-ghost" onClick={() => {
                                                setCommunicationToDelete(communication);
                                                setShowConfirmation(true);
                                                document.getElementById('confirmation_modal').showModal();
                                            }}>
                                                <FontAwesomeIcon icon={faTrash} className="h-5 w-5" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Communication;
