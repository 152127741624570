import React, { useEffect, useRef, useState } from 'react';
import {useParams, useNavigate, useLocation, Link} from "react-router-dom";
import { useQuery } from "react-query";
import { userService } from "@/_services";
import Axios from 'axios';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLockKeyhole, faEdit, faUser, faMailbox, faAt, faSignature} from "@fortawesome/pro-solid-svg-icons";
import {faCommentLines, faPencil, faUserCog, faUsers} from "@fortawesome/pro-light-svg-icons";
import {faSitemap} from "@fortawesome/pro-regular-svg-icons";
import InputUploadImage from "../../../_utils/InputUploadImage";
import Breadcrumb from "../../../components/admin/Breadcrumbs";

const UserEdit = () => {
    const { state } = useLocation();
    const user = state?.user;
    const { name: initialName, firstname: initialFirstname, email: initialEmail, role: initialRole, password:initialPassword } = user;
    const { id } = useParams();
    const navigate = useNavigate();

    const [name, setName] = useState(initialName);
    const [firstname, setFirstname] = useState(initialFirstname);
    const [email, setEmail] = useState(initialEmail);
    const [role, setRole] = useState(user.roles.find(role => ["ROLE_DEV", "ROLE_ADMIN", "ROLE_HELPDESK"].includes(role)));
    const [oldPassword, setOldPassword] = useState('');
    const handleImageChange = (e) => {
        console.log(e)
        /*const file = e.target.files[0];
        setUser({...user, profileImage: file});*/
    };
    const handleUserSubmit = async (e) => {
        e.preventDefault();

        const userToUpdate = {
            id: user.id,
            name,
            firstname,
            email,
            role
        };
        console.log("userToUpdate", userToUpdate)
        try {
            await userService.updateUserAdmin(userToUpdate);
            navigate('../index');
        } catch (error) {
            console.error("Error updating user:", error);
        }
    };

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();

        try {
            await userService.resetPassword(oldPassword);
            console.log("resetPassword", oldPassword )

            //si réponse positive, ça envoie un mail il faut donc afficher un message "vous avez reçu un mail merci de cliquer sur le lien), afficher un message success avec l'explication du mail reçu

        } catch (error) {
            console.error("Error updating user password:", error);
        }
    };

    return (
        <div>
            <div className="bg-base-100 rounded-xl shadow-md px-6 pt-6 mb-6">
                <Breadcrumb
                    firstItem={{ text: 'Utilisateurs', link: '/admin/users/index', icon: faUsers }}
                    secondItem={{ text: 'Edition', icon: faPencil }}
                />

                <div className="flex justify-between align-middle mb-6">
                    <h1 className="text-3xl font-bold m-0 p-0 leading-normal">
                        Modification de {name} {firstname}
                    </h1>
                </div>

                <form onSubmit={handleUserSubmit}>
                        <div className="flex justify-between align-middle">
                            <div className="w-2/3">
                                <div className="grid grid-cols-2 gap-2 pr-4">
                                    <label className="form-control w-full mb-6">
                                        <span className="label-text">  <FontAwesomeIcon icon={faSignature} className={"text-secondary mr-1"} /> Nom</span>
                                        <input
                                            type="text"
                                            name="name"
                                            placeholder=""
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            className="input input-bordered w-full"
                                        />
                                    </label>
                                    <label className="form-control w-full mb-6">
                                        <span className="label-text">  <FontAwesomeIcon icon={faSignature} className={"text-secondary mr-1"} /> Prénom</span>
                                        <input
                                            type="text"
                                            name="name"
                                            placeholder=""
                                            value={firstname}
                                            onChange={(e) => setFirstname(e.target.value)}
                                            className="input input-bordered w-full "
                                        />
                                    </label>
                                    <label className="form-control w-full mb-6">
                                        <span className="label-text">
                                            <FontAwesomeIcon icon={faAt} className="text-secondary mr-1" />
                                            Email
                                        </span>
                                        <input
                                            type="text"
                                            name="email"
                                            placeholder="Email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            className="input input-bordered w-full "
                                        />
                                    </label>
                                    <label className="form-control w-full mb-6">
                                        <span className="label-text">
                                           <FontAwesomeIcon icon={faSitemap} className="text-secondary mr-1" />
                                            Role
                                        </span>

                                        <select
                                            value={role}
                                            onChange={(e) => setRole(e.target.value)}
                                            className="input input-bordered w-full "
                                        >
                                            <option value="ROLE_DEV">Developer</option>
                                            <option value="ROLE_ADMIN">Admin</option>
                                            <option value="ROLE_HELPDESK">Helpdesk</option>
                                        </select>
                                    </label>
                                </div>
                                <div className="card-actions justify-between align-middle mt-2 pr-3">
                                    <button onClick={handlePasswordSubmit} className="btn btn-secondary text-white float-right">
                                        <FontAwesomeIcon icon={faLockKeyhole} />
                                        Reset Password</button>
                                    <button type="submit" className="btn btn-primary  ">
                                        <FontAwesomeIcon icon={faEdit} />
                                        Modifier</button>
                                </div>
                            </div>
                            <InputUploadImage onChange={handleImageChange} />
                        </div>



                    </form>

            </div>

        </div>
    );
};

export default UserEdit;
