import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpload} from "@fortawesome/pro-regular-svg-icons";

const InputUploadImage = ({onChange}) => {
    const [imagePreview, setImagePreview] = useState(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        onChange(file);

        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setImagePreview(e.target.result);
            };
            reader.readAsDataURL(file);
        } else {
            setImagePreview(null);
        }
    };

    return (
        <div className={"w-full flex"}>
            <div id="image-preview"
                 className={`w-full flex justify-center align-middle p-10 mb-4 bg-base-100  border border-gray-300 dark:border-neutral-600 rounded-lg items-center mx-auto text-center cursor-pointer ${imagePreview ? '' : 'border-dashed'}`}>

                <input
                    type="file"
                    id="upload"
                    className="hidden"
                    accept="image/*"
                    onChange={handleImageChange}
                />
                <label htmlFor="upload" className="cursor-pointer">
                    {imagePreview ? (
                        <div className="flex items-center justify-center">
                            <img src={imagePreview} alt={'test'} className="max-h-48 rounded-lg mx-auto"/>
                        </div>
                    ) : (
                        <div>
                            <FontAwesomeIcon icon={faUpload} className="h-10 w-10 mb-10"/>
                            <h5 className="mb-6 text-xl font-bold tracking-tight text-gray-700 dark:text-gray-100">Upload
                                picture</h5>
                            <p className="font-normal text-sm text-gray-400 md:px-6">Choose photo size should be less
                                than <b className="text-gray-600 dark:text-gray-100">2mb</b></p>
                            <p className="font-normal text-sm text-gray-400 md:px-6">and should be in <b
                                className="text-gray-600 dark:text-gray-100">JPG, PNG, or GIF</b> format.</p>
                            <span id="filename" className="text-gray-500 bg-gray-200 z-50"></span>
                        </div>
                    )
                    }

                </label>
            </div>

        </div>
    );
};

export default InputUploadImage;
