import React from 'react';
import {Route, Routes} from "react-router-dom";
import { LayoutAdmin, Dashboard, Installation } from "../Admin";
import { Users, UEdit, UAdd, UProfil } from "../Admin/Users";
import { Merchants, MEdit, MAdd } from "../Admin/Merchants";
import { Communications, ComEdit, ComAdd } from "../Admin/Communications";
import { Changelogs, CEdit, CAdd } from "../Admin/Changelogs";
import Error404 from "../../_utils/Error404";


const AdminRouter = () => {
    return (
        <div>
            <Routes>
                <Route element={<LayoutAdmin/>}>
                    <Route index element={<Dashboard />} />
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="installation" element={<Installation />} />
                    <Route path="changelogs" >
                        <Route path="index" element={<Changelogs />} />
                        <Route path="add" element={<CAdd />} />
                        <Route path="edit/:version" element={<CEdit />} />
                    </Route>
                    <Route path="communications" >
                        <Route path="index" element={<Communications />} />
                        <Route path="add" element={<ComAdd />} />
                        <Route path="edit/:id" element={<ComEdit />} />
                    </Route>
                    <Route path="users" >
                        <Route path="index" element={<Users />} />
                        <Route path="add" element={<UAdd />} />
                        <Route path="profile" element={<UProfil />} />
                        <Route path="edit/:id" element={<UEdit />} />
                    </Route>
                    <Route path="merchants" >
                        <Route path="index" element={<Merchants />} />
                        <Route path="add" element={<MAdd />} />
                        <Route path="edit" element={<MEdit />} />
                    </Route>


                    <Route path="*" element={<Error404/>}/>

                </Route>
            </Routes>
        </div>
    );
};

export default AdminRouter;