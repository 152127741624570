import React from 'react';

const MerchantEdit = () => {
    return (
        <div>
            Merchant edit
        </div>
    );
};

export default MerchantEdit;