import React from 'react';
import Alert from "@/components/Alert";

const Toast = ({ toastClass, text }) => {
    return (
        <div className={`toast toast-top toast-center`}>
            <Alert type={toastClass} message={text} />
        </div>
    );
};

export default Toast;
