import React from 'react';
import {Route, Routes} from "react-router-dom";
import { Login, PasswordLost, LayoutPublic } from "@/pages/Public";
import Error404 from "@/_utils/Error404";


const PublicRouter = () => {
    return (
        <div>
            <Routes>
                <Route element={<LayoutPublic/>}>
                    <Route index element={<Login/>} />
                    <Route path="/login" element={<Login/>} />
                    <Route path="/password-lost" element={<PasswordLost />} />

                    <Route path="*" element={<Error404 />} />
                </Route>
            </Routes>
        </div>
    );
};

export default PublicRouter;