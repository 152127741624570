import React, {useEffect, useState} from 'react';
import {userService} from "@/_services";
import InputUploadImage from '@/_utils/InputUploadImage';

const UserProfil = () => {
    const [user, setUser] = useState({
        id: '',
        username: '',
        name: '',
        firstName: '',
        email: '',
        profileImage: null
    });

    useEffect(() => {
        userService.getCurrentUser()
            .then(res => {
                setUser(res.data);
                console.log('res', res.data);
            })
            .catch(err => console.log(err));
    }, []);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setUser({...user, [name]: value});
    };

    const handleImageChange = (e) => {
        console.log(e)
        /*const file = e.target.files[0];
        setUser({...user, profileImage: file});*/
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(user)
        userService.updateUser(user)
            .then(res => {
                console.log(user)
                // Mise à jour du state pour affichage
                console.log('update');
            })
            .catch(err => console.log(err))
        // Call your service to update user profile with user state data
    };

    return (
        <div className="">
            <h1 className="text-2xl font-semibold">Mise à jour du profile</h1>
            <br/>
            <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-6 ">
                    <div>
                        <div>
                            <label htmlFor="name" className="block mb-3">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={user.name}
                                onChange={handleInputChange}
                                className="input  input-bordered w-full mb-5"
                            />
                        </div>
                        <div>
                            <label htmlFor="firstName" className="block mb-3">First Name</label>
                            <input
                                type="text"
                                id="firstName"
                                name="firstName"
                                value={user.firstName}
                                onChange={handleInputChange}
                                className="input  input-bordered w-full mb-5"
                            />
                        </div>
                        <div>
                            <label htmlFor="username" className="block mb-3">Username</label>
                            <input
                                type="text"
                                id="username"
                                name="username"
                                value={user.username}
                                onChange={handleInputChange}
                                className="input  input-bordered w-full mb-5"
                            />
                        </div>
                        <div>
                            <label htmlFor="password_old" className="block mb-3">Ancien password</label>
                            <input
                                type="text"
                                id="password_old"
                                name="password_old"
                                onChange={handleInputChange}
                                className="input  input-bordered w-full mb-5"
                            />
                        </div> <div>
                            <label htmlFor="password_old" className="block mb-3">Nouveau password</label>
                            <input
                                type="text"
                                id="password_new"
                                name="password_new"
                                onChange={handleInputChange}
                                className="input  input-bordered w-full mb-5"
                            />
                        </div>
                    </div>
                    {/*//UPLOAD IMAGE */}
                    <div>
                        <InputUploadImage onChange={handleImageChange} />

                    </div>
                </div>
                <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-md">Update</button>
            </form>
        </div>
    );
};

export default UserProfil;
