import React, {useState} from 'react';
import {useNavigate, useLocation, Link} from 'react-router-dom';
import {changelogsService} from '@/_services';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import Toast from '@/components/Toast';
import TextEditor from "@/_utils/TextEditor";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faNotes, faPencil, faUsers} from "@fortawesome/pro-light-svg-icons";
import {formatFrenchDate} from '@/_utils/DateUtils';
import Breadcrumb from "../../../components/admin/Breadcrumbs";


const ChangelogEdit = () => {

    const {state} = useLocation();
    const changelog = state?.changelog;
    const {version, date, details, status, category} = changelog;
    const [formData, setFormData] = useState({version, date, details, status, category});
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const navigate = useNavigate();
    const initialDate = formData.date ? new Date(formData.date) : new Date();
    const [previewContent, setPreviewContent] = useState(""); // État pour stocker le contenu de prévisualisation

    const [editors, setEditors] = useState(changelog?.details ? changelog.details : {
        fr: '',
        nl: '',
        en: '',
    });
    //load changelogs

    const formattedDate = formatFrenchDate(formData.date);

    const handleEditorChange = (lang, content) => {
        setEditors((prevEditors) => ({
            ...prevEditors,
            [lang]: content,
        }));
        setFormData((prevFormData) => ({
            ...prevFormData,
            details: {
                ...prevFormData.details,
                [lang]: `<div id="editor-${lang}">` + content + `</div>`,
            },
        }));

    };


    const handlePreview = (lang) => {
        // Mettre à jour le contenu de prévisualisation avec le contenu du TextEditor correspondant à la langue sélectionnée
        setPreviewContent(editors[lang]);
        // Afficher le modal
        document.getElementById(`modal-preview`).showModal();
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (formData.status === 'online') {


                const onlineChangelogExists = await changelogsService.checkOnlineChangelogExists(category);

                if (onlineChangelogExists) {
                    const onlineChangelog = await changelogsService.getOnlineChangelog(category);
                    await changelogsService.updateChangelog(onlineChangelog.id, {
                        ...onlineChangelog,
                        status: 'archived'
                    });
                }
            }

            await changelogsService.updateChangelog(changelog.id, formData);
            setSuccessMessage('Enregistrement réussi!');
            setTimeout(() => {
                navigate('../index');
            }, 1000);

        } catch (error) {
            console.error(error);
            setErrorMessage('Une erreur s\'est produite lors de l\'enregistrement: ' + error.message);
        }

        console.log(formData)
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                {/*VERSION DATE STATUS*/}
                <div className="card w-full overflow-auto  bg-base-100 rounded-xl shadow-md h-[87vh] mb-5">
                    <div className="p-3">
                        <div className="flex lg:justify-between  lg:items-center items-start p-3 lg:flex-row flex-col ">

                            <Breadcrumb
                                firstItem={{ text: 'Changelogs', link: '/admin/changelogs/index', icon: faNotes }}
                                secondItem={{ text: 'Edition', icon: faNotes }}
                            />
                            <button type="submit" className="btn btn-secondary btn-sm hidden lg:inline-flex ">
                                Enregistrer
                            </button>
                        </div>

                        <div className="flex justify-between align-middle mb-3">
                            <h1 className="lg:text-3xl font-bold m-0 p-0 leading-normal">
                                Modification du changelog version {version}
                            </h1>
                        </div>
                        <div className="flex items-center flex-wrap">
                            <div className="flex-grow pr-4 mr-4">
                                <div className="form-control mb-2">
                                    <label htmlFor="version" className="label">
                                        Version:
                                    </label>
                                    <input
                                        type="text"
                                        id="version"
                                        className="input input-bordered"
                                        value={formData.version || ''}
                                        onChange={(e) => setFormData({...formData, version: e.target.value})}
                                    />
                                </div>
                            </div>

                            <div className="flex-grow pr-4">
                                <div className="form-control mb-2">
                                    <label htmlFor="releaseDate" className="label">
                                        Release Date:
                                    </label>
                                    <DatePicker
                                        selected={initialDate}
                                        dateFormat="dd/MM/yyyy"
                                        className="input input-bordered w-full"
                                        onChange={(date) => setFormData({...formData, date: date})}
                                    />

                                </div>
                            </div>
                            <div className="flex-grow pr-4">
                                <div className="form-control mb-2">
                                    <label htmlFor="status" className="label">
                                        Status:
                                    </label>
                                    <select
                                        id="status"
                                        className="select select-bordered"
                                        value={formData.status}
                                        onChange={(e) => setFormData({...formData, status: e.target.value})}
                                    >
                                        <option value="online">✅ Online</option>
                                        <option value="draft">✏️ Draft</option>
                                        <option value="archived">🗑️ Archived</option>
                                    </select>
                                </div>
                            </div>
                            <div className="flex-grow pr-4">
                                <div className="form-control mb-2">
                                    <label htmlFor="category" className="label">
                                        Catégorie:
                                    </label>
                                    <select
                                        id="category"
                                        className="select select-bordered"
                                        value={formData.category}
                                        onChange={(e) => setFormData({...formData, category: e.target.value})}
                                    >
                                        <option value="dealer">Dealer</option>
                                        <option value="trader">Trader</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lg:p-3 p-1">
                        {/*DETAILS*/}
                        {Object.entries(editors).map(([lang, content]) => (
                            <div className="lg:pl-16 pl-0" key={lang}>
                                <div className="card w-full p-3 lg:mt-2 mb-16 lg:pb-5 pb-10">
                                    <div className="title-lang lg:inline-flex hidden lg:justify-center">
                                        <div className="text-3xl font-bold">
                                            {lang === 'fr' ? '🇫🇷 Français' : lang === 'nl' ? '🇳🇱 Néerlandais' : '🇬🇧 Anglais'}
                                        </div>
                                    </div>
                                    <div className=" inline-flex lg:hidden">
                                        <h1 className="text-2xl mb-4">{lang === 'fr' ? '🇫🇷 Français' : lang === 'nl' ? '🇳🇱 Néerlandais' : '🇬🇧 Anglais'}</h1>
                                    </div>
                                    <div className="btn btn-sm btn-ghost ml-4"
                                         style={{position: 'absolute', right: '18px', top: '18px'}}
                                         onClick={() => handlePreview(lang)}>
                                        <FontAwesomeIcon icon={faEye}/> Preview
                                    </div>
                                    <TextEditor
                                        key={lang}
                                        style={{height: '300px', marginBottom: '30px'}}
                                        lang={lang}
                                        content={content}
                                        handleEditorChange={handleEditorChange}
                                    />


                                </div>
                            </div>


                        ))}

                    </div>
                    <button type="submit" className="btn btn-secondary mt-10 lg:hidden inline-flex w-full ">
                        Enregistrer
                    </button>
                </div>

            </form>


            <dialog id={`modal-preview`} className="modal modal-changelog">
                <div className="modal-box p-0">
                    <div className="modal-header">
                        <button onClick={() => document.getElementById(`modal-preview`).close()}
                                className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 z-50">✕
                        </button>

                        <span className="badge ltu">{formattedDate}</span>
                        <h5>News &amp; updates</h5>
                        <div className="overlay"></div>
                        <div className="overlay-europe"></div>
                    </div>
                    <div className="modal-body">
                        <div className="preview-content changelogs"
                             dangerouslySetInnerHTML={{__html: previewContent}}></div>
                    </div>
                    <div className="modal-footer d-flex align-items-center justify-content-end mt-2 ">
                        <div onClick={() => document.getElementById(`modal-preview`).close()}
                             className="btn btn-danger modal-close-btn btn-block">
                            J'ai compris - continuer
                        </div>
                    </div>

                </div>
                <form method="dialog" className="modal-backdrop">
                    <button onClick={() => document.getElementById(`modal-preview`).close()}>close</button>
                </form>
            </dialog>

            {successMessage && (
                <Toast toastClass="success" text={successMessage}/>
            )}
            {errorMessage && (
                <Toast toastClass="danger" text={errorMessage}/>
            )}

        </div>
    );
};

export default ChangelogEdit;
