import React from 'react';
import { useData } from '../_helpers/Context';

const withData = (Component) => (props) => {
    const { data, setSpecificData } = useData();

    const specificData = data[props.dataType]; // Récupérer les données en fonction du type

    const handleChange = (newData) => setSpecificData(props.dataType, newData);

    return <Component data={specificData} handleChange={handleChange} {...props} />;
};

export default withData;
