import React, { useState, useEffect } from 'react';
import { faFlask, faRocket, faUser, faCar, faUserTie } from "@fortawesome/pro-regular-svg-icons";
import Stat from "@/components/admin/Stats";
import { useData } from "@/_helpers/Context"; // Importez useData pour accéder aux données

const Dashboard = () => {
    const { data } = useData(); // Obtenez les données du contexte
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Mettez à jour l'état du chargement une fois que les données du currentUser et du tableau de bord sont disponibles
        if (Object.keys(data.currentUser).length > 0 && Object.keys(data.dashboardStats).length > 0) {
            setLoading(false);
        }
    }, [data]);

    // Affichez le chargement tant que les données ne sont pas disponibles
    if (loading) {
        return (
            <div className="h-screen flex items-center justify-center -mt-16">
                <span className="loading loading-ring loading-lg h-28 w-28"></span>
            </div>
        );
    }

    return (
        <div className="Dashboard">
            <div className="content">
                <h1 className="text-5xl mb-16">
                    Bonjour <br />
                    {data.currentUser.firstname && (
                        <strong className="lg:text-8xl">{data.currentUser.firstname}</strong>
                    )}
                </h1>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
                    <Stat icon={faFlask} title="Serveurs en acceptance" value={data.dashboardStats.acceptance} />
                    <Stat icon={faRocket} title="Serveurs en production" value={data.dashboardStats.production} />
                    <Stat icon={faCar} title="Voitures sur Trade" value={data.dashboardStats.carCount} />
                    <Stat icon={faUser} title="Marchands lite" value={data.dashboardStats.lightMerchantCount} />
                    <Stat icon={faUserTie} title="Marchands premium" value={data.dashboardStats.premiumMerchantCount} />
                    <Stat icon={faUserTie} title="Marchands totaux" value={data.dashboardStats.totalMerchantCount} />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
