import Axios from "@/_services/caller.service";


let getCurrentUser = (token) => {
    return Axios.post('/users/current', {}, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    });
}

let getAllUsers = async () => {
    const {data} = await Axios.get('/users/')
    return data
}

let addUser = (user) => {
    console.log('addUser', user)
    return Axios.post(`/users/create`, user)
}

let deleteUser = (id) => {
    return Axios.delete(`/users/delete/${id}/delete`)
}

let updateUser = (user) => {
    return Axios.put(`/users/users/update`, user)
}
let updateUserAdmin = (user) => {
    return Axios.put(`/users/${user.id}/update`, user)
}

let resetPassword = (email) => {
    return Axios.put(`/reset-password/`, email)
}

//isLoggedIn


export const userService = {
    getCurrentUser,
    getAllUsers,
    updateUserAdmin,
    resetPassword,
    addUser,
    updateUser,
    deleteUser,
}