import React from 'react';

const MerchantAdd = () => {
    return (
        <div>
            Merchant add
        </div>
    );
};

export default MerchantAdd;