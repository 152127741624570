import React from 'react';

const AnimalIcon = () => {
    // Tableau d'icônes d'animaux
    const animalIcons = ['🐼', '🐵', '🦁', '🐯', '🐶', '🐱', '🐭', '🐻', '🐨', '🐷'];

    // Fonction pour choisir une icône aléatoire
    const getRandomAnimalIcon = () => {
        const randomIndex = Math.floor(Math.random() * animalIcons.length);
        return animalIcons[randomIndex];
    };

    // Icône aléatoire
    const randomIcon = getRandomAnimalIcon();

    return (
        <>
        {randomIcon}
        </>
    );
};

export default AnimalIcon;
