import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import { communicationsService } from '@/_services';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TextEditor from '@/_utils/TextEditor';
import Toast from '@/components/Toast';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCommentLines, faEye, faFilter, faNotes, faPencil} from "@fortawesome/pro-light-svg-icons";
import { formatFrenchDate } from '@/_utils/DateUtils';
import Dropdown from "@/components/Dropdown";
import Checkbox from "@/components/Checkbox";
import {useData} from "@/_helpers/Context";
import Breadcrumb from "../../../components/admin/Breadcrumbs";

const CommunicationAdd = () => {
    const { data, setSpecificData } = useData();

    const { communications } = data;
    const navigate = useNavigate();
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [previewContent, setPreviewContent] = useState("");
    const [subject, setSubject] = useState('');
    const [dateStarting, setDateStarting] = useState(new Date());
    const [dateEnding, setDateEnding] = useState(new Date());
    const [releaseDate, setReleaseDate] = useState(new Date());
    const [status, setStatus] = useState('draft');
    const [category, setCategory] = useState('dealer');
    const [tagList, setTagsList] = useState('');
    const [tags, setTags] = useState('');
    const [countriesList, setCountriesList] = useState('');
    const [selectedOptions, setSelectedOptions] = useState({});
    const [filterTags, setFilterTags] = useState('');
    const [formData, setFormData] = useState({
        id: '',
        title: '',
        category: '',
        date_starting: new Date(),
        date_ending: new Date(),
        status: 'draft',
        contents: {
            fr: {
                "title": "",
                "content": ""
            },
            en: {
                "title": "",
                "content": ""
            },
            nl: {
                "title": "",
                "content": ""
            }
        },
        languages: [],
        users_status: [],
        countries: [],
        tags: [],
        contrat: [],
    });
    const formattedDate = formatFrenchDate(formData.date);

    const [isLoading, setLoading] = useState(true);


    const handleTitleChange = (lang, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            contents: {
                ...prevFormData.contents,
                [lang]: {
                    ...prevFormData.contents[lang],
                    title: value,
                },
            },
        }));
    };

    const handleOptionToggle = (category, value) => {
        setSelectedOptions(prevOptions => {
            const updatedOptions = { ...prevOptions };
            if (!updatedOptions[category]) {
                updatedOptions[category] = [];
            }
            if (updatedOptions[category].includes(value)) {
                updatedOptions[category] = updatedOptions[category].filter(option => option !== value);
            } else {
                updatedOptions[category].push(value);
            }
            return { ...updatedOptions };
        });
    };


    const handleEditorChange = (lang, content) => {
        const langTitle = document.getElementById(`title-${lang}`).value;

        setFormData((prevFormData) => ({
            ...prevFormData,
            id:0,
            title: subject,
            category: category,
            date_starting: dateStarting,
            date_ending: dateEnding,
            status: status,
            contents: {
                ...prevFormData.contents,
                [lang]: {
                    title: langTitle,
                    content: content,
                },
            },
            languages: selectedOptions["languages"] || [],
            users_status: selectedOptions["status_user"] || [],
            countries: selectedOptions["countries"] || [],
            tags: selectedOptions["tags"] || [],
            contrat: selectedOptions["contrat"] || [],
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            console.log("submit", formData);

            const formattedDateStarting = dateStarting.toISOString().split('T')[0];
            const formattedDateEnding = dateEnding.toISOString().split('T')[0];
            const formattedFormData = {
                id:0,
                title: subject,
                category: category,
                date_starting: formattedDateStarting,
                date_ending: formattedDateEnding,
                status: formData.status,
                contents: formData.contents,
                languages: formData.languages,
                users_status: formData.users_status,
                countries: formData.countries,
                tags: formData.tags,
                contrat: formData.contrat,
            };

            await communicationsService.addCommunication(formattedFormData);
            console.log("submit formattedFormData", formattedFormData)


            //replace id from formattedFormData with lastID
            const lastID = await communicationsService.getLastCommunicationId();
            formattedFormData.id = lastID;

            let  updatedCommunications = [...communications, formattedFormData];
            setSpecificData('communications', updatedCommunications);
            console.log("submit updatedCommunications context", updatedCommunications)

            setSuccessMessage('Enregistrement réussi!');
            setTimeout(() => {
                navigate('../index');
            }, 2500);

        } catch (error) {
            console.log(formData)
            console.error(error);
            setErrorMessage('Une erreur s\'est produite lors de l\'enregistrement: ' + error.message);
        }
    };

    const handlePreview = (content) => {
        setPreviewContent(content);
        document.getElementById(`modal-preview`).showModal();
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const dataTags = await communicationsService.getAllTags();
                const tagsObject = {};

                dataTags.forEach(tag => {
                    tagsObject[tag.id] = tag.name;
                });

                const tagsArray = [tagsObject];

                const dataCountries = await communicationsService.getAllCountries();
                const countriesArray = Object.values(dataCountries);
                setCountriesList(countriesArray);
                setTags(tagsArray);
                setLoading(false);

            } catch (error) {
                setLoading(false);

            }
        };
        fetchData();
    }, []);


    return (
        <div>

            {isLoading && (
                <div className="text-center h-full align-middle justify-center">
                    <span className="loading loading-spinner loading-lg"></span>
                </div>
            )}
            {!isLoading && (
                <>

            <form onSubmit={handleSubmit}>
                {/* VERSION DATE STATUS */}
                <div className="card w-full overflow-auto  bg-base-100 rounded-xl shadow-md h-[87vh] mb-5">
                    <div className="p-3">
                        <div className="flex lg:justify-between items-start lg:p-3 lg:flex-row flex-col ">
                            <Breadcrumb
                                firstItem={{ text: 'Communication', link: '/admin/communications/index', icon: faCommentLines }}
                                secondItem={{ text: 'Nouveau', icon: faPencil }}
                            />


                            <button type="submit" className="btn btn-secondary btn-sm hidden lg:inline-flex ">
                                Enregistrer
                            </button>
                        </div>
                        <div className="flex lg:items-center lg:flex-row flex-col items-start gap-2">
                            <div className="flex-grow w-full">
                                <div className="form-control mb-2">
                                    <label htmlFor="subject" className="label">
                                        Titre:
                                    </label>
                                    <input
                                        type="text"
                                        id="subject"
                                        className="input input-bordered"
                                        value={subject}
                                        onChange={(e) => setSubject(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="flex-grow  w-full">
                                <div className="form-control mb-2">
                                    <label htmlFor="releaseDate" className="label">
                                         Date de début:
                                    </label>
                                    <DatePicker
                                        selected={dateStarting}
                                        dateFormat="dd/MM/yyyy"
                                        className="input input-bordered w-full"
                                        onChange={(date) => setDateStarting(date)}
                                    />
                                </div>
                            </div>
                            <div className="flex-grow  w-full">
                                <div className="form-control mb-2">
                                    <label htmlFor="releaseDate" className="label">
                                         Date de fin:
                                    </label>
                                    <DatePicker
                                        selected={dateEnding}
                                        dateFormat="dd/MM/yyyy"
                                        className="input input-bordered w-full"
                                        onChange={(date) => setDateEnding(date)}
                                    />
                                </div>
                            </div>
                            <div className="flex-grow  w-full">
                                <div className="form-control mb-2">
                                    <label htmlFor="status" className="label">
                                        Status:
                                    </label>
                                    <select
                                        id="status"
                                        className="select select-bordered"
                                        value={status}
                                        onChange={(e) => setStatus(e.target.value)}
                                    >
                                        {/*<option value="online">✅ Online</option>*/}
                                        <option value="draft">✏️ Draft</option>
                                        {/*<option value="archived">🗑️ Archived</option>*/}
                                    </select>
                                </div>
                            </div>
                            <div className="flex-grow  w-full">
                                <div className="form-control mb-2">
                                    <label htmlFor="status" className="label">
                                        Catégorie:
                                    </label>
                                    <select
                                        id="status"
                                        className="select select-bordered"
                                        value={category}
                                        onChange={(e) => setCategory(e.target.value)}
                                    >
                                        <option value="dealer">Dealer</option>
                                        <option value="trader">Trader</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="mb-4 flex items-center flex-wrap gap-2 ">
                            <Dropdown title="🗣️ &nbsp; Langues" style="input-bordered">
                                    <Checkbox
                                        label="🇫🇷 &nbsp; Français"
                                        checked={selectedOptions["languages"]?.includes("fr") || false}
                                        onChange={() => handleOptionToggle("languages","fr")}
                                    />
                                <Checkbox
                                    label="🇳🇱 &nbsp; Néerlandais"
                                    checked={selectedOptions["languages"]?.includes("nl") || false}
                                    onChange={() => handleOptionToggle("languages","nl")}
                                />
                                <Checkbox
                                    label="🇬🇧 &nbsp; Anglais"
                                    checked={selectedOptions["languages"]?.includes("en") || false}
                                    onChange={() => handleOptionToggle("languages","en")}
                                />
                                <Checkbox
                                    label="🇩🇪 &nbsp; Allemand"
                                    checked={selectedOptions["languages"]?.includes("de") || false}
                                    onChange={() => handleOptionToggle("languages","de")}
                                />

                            </Dropdown>
                            {countriesList && countriesList.length > 0 && (
                                <Dropdown title="🌍 &nbsp;  Pays" style="input-bordered">
                                    {countriesList.map(country => (
                                        <Checkbox
                                            key={country.id}
                                            label={<><span>{country.flag}</span>&nbsp;{country.name}</>}
                                            checked={selectedOptions["countries"]?.includes(country.id) || false}
                                            onChange={() => handleOptionToggle("countries", country.id)}
                                        />
                                    ))}
                                </Dropdown>
                            )}


                            <Dropdown title="🧑‍💼 &nbsp; Status utilisateur" style="input-bordered">
                                {category === 'dealer' && (
                                    <>
                                        <Checkbox
                                        label="Vendeur"
                                        checked={selectedOptions["status_user"]?.includes(6) || false}
                                        onChange={() => handleOptionToggle("status_user",6)}
                                    />
                                    <Checkbox
                                        label="Modérateur"
                                        checked={selectedOptions["status_user"]?.includes(8) || false}
                                        onChange={() => handleOptionToggle("status_user",8)}
                                    />
                                    <Checkbox
                                        label="SAV"
                                        checked={selectedOptions["status_user"]?.includes(5) || false}
                                        onChange={() => handleOptionToggle("status_user",5)}
                                    />
                                    <Checkbox
                                        label="Support"
                                        checked={selectedOptions["status_user"]?.includes(18) || false}
                                        onChange={() => handleOptionToggle("status_user",18)}
                                    />
                                    <Checkbox
                                        label="Direction"
                                        checked={selectedOptions["status_user"]?.includes(10) || false}
                                        onChange={() => handleOptionToggle("status_user",10)}
                                    />
                                        <Checkbox
                                            label="Admin"
                                            checked={selectedOptions["status_user"]?.includes(19) || false}
                                            onChange={() => handleOptionToggle("status_user", 19)}
                                        />
                                        <Checkbox
                                            label="Super-Admin"
                                            checked={selectedOptions["status_user"]?.includes(20) || false}
                                            onChange={() => handleOptionToggle("status_user", 20)}
                                        />
                                </>
                                )}
                                {category === 'trader' && (
                                    <Checkbox
                                        label="Admin only"
                                        checked={selectedOptions["status_user"]?.includes("masterUser") || false}
                                        onChange={() => handleOptionToggle("status_user", "masterUser")}
                                    />
                                )}

                            </Dropdown>
                            {/*tags && tags.length > 0 && (
                                <Dropdown title="🔖  &nbsp; Tags" style="input-bordered ">
                                    <input
                                        type="text"
                                        className="input input-bordered input-sm w-full max-w-xs my-3"
                                        placeholder="Rechercher un tag..."
                                        value={filterTags}
                                        onChange={(e) => setFilterTags(e.target.value)}
                                    />
                                    {tags.map((tagGroup, index) => (
                                        tagGroup && Object.entries(tagGroup).map(([id, label]) => {
                                            if (typeof label === 'string' && label.toLowerCase().includes(filterTags.toLowerCase())) {
                                                return (
                                                    <div key={id}>
                                                        <li key={id}>
                                                            <Checkbox
                                                                className={"capitalize"}
                                                                label={label}
                                                                checked={selectedOptions["tags"]?.includes(id) || false}
                                                                onChange={() => handleOptionToggle("tags", id)}
                                                            />
                                                        </li>
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })
                                    ))}
                                </Dropdown>
                            )*/}
                            {category === 'trader' && (
                                <Dropdown title="📑 &nbsp; Type de contrat" style="input-bordered">
                                    <Checkbox
                                        label="Lite"
                                        checked={selectedOptions["contrat"]?.includes("lite") || false}
                                        onChange={() => handleOptionToggle("contrat","lite")}
                                    />
                                    <Checkbox
                                        label="Premium"
                                        checked={selectedOptions["contrat"]?.includes("premium") || false}
                                        onChange={() => handleOptionToggle("contrat","premium")}
                                    />
                                </Dropdown>
                            )}
                        </div>
                    </div>
                    <div className="p-3">


                    {/* DETAILS */}
                {['fr', 'nl', 'en'].map((lang) => (
                    <div className="lg:pl-16 pl-0" key={lang}>
                        <div className="card w-full p-3 lg:mt-2 mb-16 lg:pb-5 pb-10">
                            <div className="title-lang lg:inline-flex hidden lg:justify-center" style={{width: '390px',left: '-217px',top: '174px'}}>
                                <div className="text-3xl font-bold">
                                    {lang === 'fr' ? '🇫🇷 Français' : lang === 'nl' ? '🇳🇱 Néerlandais' : '🇬🇧 Anglais'}
                                </div>
                            </div>
                            <div className=" inline-flex lg:hidden">
                                <h1 className="text-2xl mb-4">{lang === 'fr' ? '🇫🇷 Français' : lang === 'nl' ? '🇳🇱 Néerlandais' : '🇬🇧 Anglais'}</h1>
                            </div>
                            <div className="btn btn-sm btn-ghost ml-4" style={{position: 'absolute', right: '18px',top: '18px'}} onClick={() => handlePreview(formData.contents[lang])} >
                                <FontAwesomeIcon icon={faEye} /> Preview
                            </div>

                            <div className={"title-input"}>
                                <input
                                    type="text"
                                    id={`title-${lang}`}
                                    className="input input-bordered w-full"
                                    placeholder={`Titre ${lang}`}
                                    style={{borderRadius:'0px 10px 0px 0px', marginBottom:'-1px'}}
                                    value={formData.contents[lang].title}
                                    onChange={(e) => handleTitleChange(lang, e.target.value)}
                                />
                            </div>
                                <TextEditor
                                    key={lang}
                                    lang={lang}
                                    style={{height: '300px', marginBottom: '30px'}}
                                    content={formData.contents[lang].content}
                                    handleEditorChange={handleEditorChange}
                                />
                        </div>

                    </div>
                ))}
                    </div>
                    <button type="submit" className="btn btn-secondary mt-10 lg:hidden inline-flex w-full ">
                        Enregistrer
                    </button>
                </div>

            </form>
            <dialog id={`modal-preview`} className="modal modal-communication">
                <div className="modal-box p-0">
                    <div className="modal-header">
                        <button onClick={() => document.getElementById(`modal-preview`).close()} className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 z-50">✕</button>

                        <h5 dangerouslySetInnerHTML={{ __html: previewContent.title }}></h5>
                        <div className="overlay"></div>
                        <div className="overlay-europe"></div>
                    </div>
                    <div className="modal-body">
                        <div className="preview-content changelogs" dangerouslySetInnerHTML={{ __html: previewContent.content }}></div>
                    </div>
                    <div className="modal-footer d-flex align-items-center justify-content-end mt-2">
                        <div onClick={() => document.getElementById(`modal-preview`).close()} className="btn btn-danger modal-close-btn btn-block">
                            J'ai compris - continuer
                        </div>
                    </div>

                </div>
                <form method="dialog" className="modal-backdrop">
                    <button onClick={() => document.getElementById(`modal-preview`).close()}>close</button>
                </form>
            </dialog>
                </>
            )}

            {successMessage && (
                <Toast toastClass="success" text={successMessage} />
            )}
            {errorMessage && (
                <Toast toastClass="danger" text={errorMessage} />
            )}
        </div>
    );
};

export default CommunicationAdd;
