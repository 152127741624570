import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Breadcrumb = ({ firstItem, secondItem = null  }) => {
    const items = [firstItem];
    if (secondItem) items.push(secondItem);

    return (
        <div className="text-sm breadcrumbs mb-2">
            <ul>
                {items.map((item, index) => (
                    <li key={index}>
                        {item.link ? (
                            <Link to={item.link}>
                                {item.icon && <FontAwesomeIcon icon={item.icon} className="text-primary mr-3" />}
                                {item.text}
                            </Link>
                        ) : (
                            <>
                                {item.icon && <FontAwesomeIcon icon={item.icon} className="text-primary mr-3" />}
                                {item.text}
                            </>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Breadcrumb;
