import React from 'react';

const Content = ({ children }) => {
    return (
        <div className="card w-full p-3 bg-base-100 rounded-xl shadow-md overflow-hidden h-[87vh] mb-10">
            {children}
        </div>
    );
};

export default Content;
