
import {BrowserRouter, Route, Routes} from "react-router-dom";
import AdminRouter from "@/pages/Admin/AdminRouter";
import AuthGuard from "@/_helpers/AuthGuard";
import PublicRouter from "@/pages/Public/PublicRouter";
import DataProvider from "@/_helpers/Context"; // Importez le DataProvider

function App() {
  return (
    <div className="App">
        <BrowserRouter>
            <Routes>
                <Route path="/*" element={<PublicRouter />} />

                <Route path="/admin/*" element={
                    <AuthGuard>
                        <DataProvider>
                            <AdminRouter />
                        </DataProvider>
                    </AuthGuard>
                } />
            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
