import React, {useEffect, useRef, useState, useLayoutEffect} from 'react';
import Logger from "@/_utils/Logger";
import {installationService} from "@/_services/installation.service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCarBuilding, faCommentLines,
    faMapLocation,
    faNotes,
    faRocketLaunch,
    faScrewdriverWrench
} from "@fortawesome/pro-light-svg-icons";
import {Link} from "react-router-dom";
import Breadcrumb from '@/components/admin/Breadcrumbs';

const Installation = () => {
    const [error, setError] = useState(null);
    const [concessions, setConcessions] = useState([{}]);
    // eslint-disable-next-line no-unused-vars
    const [coordinates, setCoordinates] = useState('');
    const [queries, setQueries] = useState({});
    const queriesContainerRef = useRef(null);
    const [isMounted, setIsMounted] = useState(false);
    const [shouldScroll, setShouldScroll] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingLong, setLoadingLong] = useState(false);
    const [deletingConcessionIndex, setDeletingConcessionIndex] = useState(null);



    useEffect(() => {
        if (!isMounted) {
            setIsMounted(true);
            queriesContainerRef.current = document.querySelector('#queries-container');
        }
    }, []);


    useLayoutEffect(() => {
        if (isMounted && shouldScroll) { // Vérifiez si le montage est terminé et si le défilement est nécessaire
            const lastConcessionIndex = concessions.length - 1;
            const lastConcessionElement = document.querySelector(`#concession-${lastConcessionIndex}`);
            if (lastConcessionElement) {
                lastConcessionElement.scrollIntoView({behavior: 'smooth', block: 'start'});
                setShouldScroll(false); // Réinitialisez le flag de défilement
            }
        }
    }, [isMounted, concessions, shouldScroll]);
    const handleCalculateCoordinates = async (index) => {
        setLoadingLong(true);
        const concession = concessions[index];
        const address = concession[`adresse_${index}`];
        const ville = concession[`ville_${index}`];
        const codePostal = concession[`codePostal_${index}`];
        const paysElement = document.querySelector(`select[name="pays_${index}"]`);
        let pays = null;

        if (paysElement) {
            pays = paysElement.options[paysElement.selectedIndex].textContent;
        } else {
            console.error("Element not found in the DOM");
            return;
        }

        const fullAddress = `${address} ${ville} ${codePostal} ${pays}`;
        try {
            const coordinates = await installationService.getCoordinates(fullAddress);
            setCoordinates(coordinates);
            setLoadingLong(false);
            handleInputChange(index, {target: {name: 'latitude', value: coordinates}});
            setError(null);
        } catch (error) {
            setLoadingLong(false);

            setError(error.message);
            Logger('error', 'Error getting coordinates:', error);
        }
    };
    const handleAddConcession = () => {
        setConcessions(prevConcessions => [...prevConcessions, {}]);
        setIsMounted(true);
        setShouldScroll(true);
    };


    const handleInputChange = (index, event) => {

        const {name, value} = event.target;
        const list = [...concessions];
        list[index] = {
            ...list[index],
            [name]: value
        };
        setConcessions(list);
    };



    const handleRemoveConcession = (e, index) => {
        e.preventDefault();
        setDeletingConcessionIndex(index);
        setTimeout(() => {
            setConcessions(prevConcessions => {
                const newList = [...prevConcessions];
                newList.splice(index, 1);
                return newList;
            });
            setDeletingConcessionIndex(null);
        }, 500);
    };



    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        const formData = new FormData(event.target);

        const checkboxes = event.target.querySelectorAll('input[type="checkbox"]');
        checkboxes.forEach(checkbox => {
            if (!checkbox.checked) {
                formData.append(checkbox.name, '');
            }
        });

        const data = {};
        formData.forEach((value, key) => {
            if (key.includes('_')) {
                const [fieldName, concessionIndex] = key.split('_');
                if (!data.concessions) {
                    data.concessions = {};
                }
                if (!data.concessions[concessionIndex]) {
                    data.concessions[concessionIndex] = {};
                }
                data.concessions[concessionIndex][fieldName] = value;
            } else {
                data[key] = value;
            }
        });

        try {
            console.log('data', data);
            const response = await installationService.getQueries(data);
            console.log('response', response)
            if (response) {
                const queriesData = {
                    config: response.config ?? '',
                    appConfig: response.appconfig ?? '',
                    connection: response.connection ?? '',
                    query_compta: response.query_compta ?? '',
                    query_local: response.query_local ?? '',
                    query_reporting: response.query_reporting ?? '',
                    query_trade: response.query_trade ?? ' '
                };

                setQueries(queriesData);
                if (queries && queriesContainerRef.current) { // Check if queries and ref are available
                    setLoading(false);

                    queriesContainerRef.current.scrollIntoView({behavior: 'smooth'});

                }

            }
        } catch (error) {
            setLoading(false);
            setError(error.message);
        }
    };
    const handleCopyQuery = async (index) => {
        try {
            const queryValueElement = document.querySelector(`.copy-query-${index}`);
            if (queryValueElement) {
                const queryText = queryValueElement.textContent;
                await navigator.clipboard.writeText(queryText.trim());
                // Mettre à jour le texte du bouton une fois la copie réussie
                const button = document.querySelector(`.copy-button-${index}`);
                if (button) {
                    button.textContent = "Copié ✅";
                }
            } else {
                console.error(`Element with class copy-query-${index} not found`);
            }
        } catch (error) {
            console.error("Error copying query:", error);
        }
    };


    return (
        <form onSubmit={handleSubmit}>
            <div className="card w-full bg-base-100 rounded-xl shadow-md overflow-auto h-[85vh] mb-10 ">
                <div className="flex lg:justify-between  lg:items-center items-start p-3 lg:flex-row flex-col ">
                    <Breadcrumb
                        firstItem={{ text: 'Installation', icon: faScrewdriverWrench }}
                    />
                    <div className="flex items-center flex-wrap">
                        <div className="flex lg:items-center content-start flex-wrap lg:flex-row flex-col">
                            <div className="form-control flex flex-row">
                                <label className="label cursor-pointer">
                                    <input type="checkbox" name="onlyMoto" id="onlyMoto" className="toggle mr-2"/>
                                    <span className="label-text mr-5">Only moto</span>
                                </label>
                            </div>
                            <div className="form-control flex flex-row">
                                <label className="label cursor-pointer">
                                    <input type="checkbox" className="toggle mr-2" name="spoticar" id="spoticar"/>
                                    <span className="label-text mr-5"> Spoticar</span>
                                </label>
                            </div>
                            <div className="form-control flex flex-row">
                                <label className="label cursor-pointer">
                                    <input type="checkbox" className="toggle  mr-2" name="misiv"/>
                                    <span className="label-text mr-5"> Misiv</span>

                                </label>
                            </div>
                            <div className="form-control flex flex-row">
                                <label className="label cursor-pointer">
                                    <input type="checkbox" className="toggle  mr-2" name="fullpack"/>
                                    <span className="label-text mr-5"> Full Pack</span>
                                </label>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-sm btn-secondary ml-4 hidden lg:inline-flex">
                            {loading ? (
                                <span className="loading loading-ring"></span>
                            ) : (
                                <span className="text-sm">
                                    <FontAwesomeIcon icon={faRocketLaunch} className={"text-lg mr-2"} /> Générer les données
                                </span>
                            )}
                        </button>
                    </div>

                </div>

                <div className="">

                <div className="grid lg:grid-cols-3 gap-6 mb-5 p-3">
                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">URL</span>
                        </label>
                        <input type="text"
                               name="url"
                               defaultValue="https://www."
                               placeholder="URL"
                               className="input input-bordered" required/>
                    </div>
                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">Code de DB</span>
                        </label>
                        <input
                            type="text"
                            name="ftp"
                            placeholder="Exemple : nnff_nom-du-concessionaire"
                            className="input input-bordered" required/>
                    </div>
                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">Mot de passe BD:</span>
                        </label>

                        <input
                            type="text"
                            name="password"
                            placeholder="Password"
                            className="input input-bordered" required/>
                    </div>
                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">Hash</span>
                        </label>
                        <input
                            type="text"
                            name="hash"
                            placeholder="Exemple : quent121"
                            className="input input-bordered" required/>
                    </div>
                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">HashB</span>
                        </label>
                        <input type="text" name="hashB" placeholder="Hash B"
                               className="input input-bordered" required/>
                    </div>
                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">Dealer Code</span>
                        </label>
                        <input
                            type="text"
                            placeholder="Nom du dealer en minuscule sans espace"
                            name="dealerCode"
                            className="input input-bordered" required/>
                    </div>
                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">Vendeur</span>
                        </label>
                        <input type="text" name="vendeur" placeholder="Enrico" className="input input-bordered"
                               required/>
                    </div>
                </div>


                <div className="dealers-container mt-10">
                    {concessions.map((concession, index) => (
                        <div key={index} id={`concession-${index}`} className={`dealer-row bg-base-100 px-4 pt-3 pb-5 mb-5 ${deletingConcessionIndex === index ? 'deleting' : ''}`}>

                        <div className="flex items-center justify-between mb-3">
                                <h2 className="mb-5 text-1xl font-bold"><FontAwesomeIcon icon={faCarBuilding} className="text-primary" /> Concession #{index + 1}</h2>
                                {index === 0 && (
                                    <button className="btn btn-primary" id="add-dealer"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                handleAddConcession()
                                            }}>Ajouter une concession</button>
                                )}

                                {index > 0 && (
                                    <button
                                        className="btn btn-error"
                                        onClick={(e) => handleRemoveConcession(e, index)}
                                    >
                                        Supprimer cette concession
                                    </button>
                                )}

                            </div>

                            <div className="grid lg:grid-cols-3 gap-6">
                                {/* Champs de formulaire pour chaque concession */}
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Nom</span>
                                    </label>
                                    <input type="text" name={`nom_${index}`} value={concession.nom} placeholder="Nom"
                                           className="input input-bordered" onChange={e => handleInputChange(index, e)}
                                           required/>
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Nom de facturation</span>
                                    </label>
                                    <input type="text" name={`nameBilling_${index}`} value={concession.nameBilling}
                                           placeholder="Nom de facturation" className="input input-bordered"
                                           onChange={e => handleInputChange(index, e)} required/>
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Adresse</span>
                                    </label>
                                    <input type="text" name={`adresse_${index}`} value={concession.adresse}
                                           placeholder="Adresse"
                                           className="input input-bordered" onChange={e => handleInputChange(index, e)}
                                           required/>
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Ville</span>
                                    </label>
                                    <input type="text" name={`ville_${index}`} value={concession.ville}
                                           placeholder="Ville"
                                           className="input input-bordered" onChange={e => handleInputChange(index, e)}
                                           required/>
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Code Postal</span>
                                    </label>
                                    <input type="text" name={`codePostal_${index}`} value={concession.codePostal}
                                           placeholder="Code Postal" className="input input-bordered"
                                           onChange={e => handleInputChange(index, e)} required/>
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Pays</span>
                                    </label>
                                    <select name={`pays_${index}`} value={concession.pays}
                                            className="select select-bordered"
                                            onChange={e => handleInputChange(index, e)} data-index={index} required>
                                        <option value="">Sélectionner un pays</option>
                                        <option value="24">Belgique</option>
                                        <option value="127">Luxembourg</option>
                                        <option value="77">France</option>
                                        <option value="208">Suisse</option>
                                        <option value="84">Grèce</option>
                                        <option value="172">Pays-bas</option>
                                        <option value="244">Bulgarie</option>
                                        <option value="246">Roumanie</option>
                                    </select>
                                </div>


                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Téléphone</span>
                                    </label>
                                    <input type="text"
                                           name={`telephone_${index}`}
                                           value={concession.telephone}
                                           placeholder="Téléphone"
                                           className="input input-bordered"
                                           onChange={e => handleInputChange(index, e)} required/>
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Bank</span>
                                    </label>
                                    <input type="text"
                                           name={`bank_${index}`}
                                           placeholder="Numéro de compte bancaire"
                                           value={concession.bank}
                                           className="input input-bordered"
                                           onChange={e => handleInputChange(index, e)} required/>

                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">TVA</span>
                                    </label>
                                    <input type="text" name={`TVA_${index}`} value={concession.tva}
                                           placeholder="Numéro de TVA"
                                           className="input input-bordered" onChange={e => handleInputChange(index, e)}
                                           required/>

                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Zone</span>
                                    </label>
                                    <select name={`zone_${index}`} value={concession.zone}
                                            className="select select-bordered"
                                            onChange={e => handleInputChange(index, e)} required>
                                        <option value="">Sélectionner une zone</option>
                                        <option value="4">Flandre Ouest / Occidentale</option>
                                        <option value="5">Flandre Est / Orientale</option>
                                        <option value="6">Anvers</option>
                                        <option value="7">Brab Flamand</option>
                                        <option value="8">Limbourg</option>
                                        <option value="9">Bruxelles</option>
                                        <option value="10">Brab Wallon</option>
                                        <option value="11">Hainaut</option>
                                        <option value="12">Namur</option>
                                        <option value="13">Liège</option>
                                        <option value="14">Luxembourg</option>
                                        <option value="127">Luxembourg (Pays)</option>
                                        <option value="77">France</option>
                                        <option value="208">Suisse</option>
                                        <option value="84">Grèce</option>
                                        <option value="172">Pays-bas</option>
                                        <option value="244">Bulgarie</option>
                                        <option value="246">Roumanie</option>
                                    </select>
                                </div>


                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Latitude</span>
                                    </label>
                                    <div className="flex flex-row">
                                        <input
                                            type="text"
                                            name={`latitude_${index}`}
                                            value={concession.latitude}
                                            placeholder="Latitude"
                                            className="input input-bordered basis-3/4"
                                            onChange={(e) => handleInputChange(index, e)}
                                            required
                                        />
                                        <button
                                            className="basis-1/4 btn btn-outline-secondary calculate-button ms-2 "
                                            onClick={(event) => {
                                                event.preventDefault();
                                                handleCalculateCoordinates(index)
                                            }}
                                            required
                                        >

                                            {loadingLong ? (
                                                <span className="loading loading-ring  "></span>

                                            ) : (
                                                <span className="text-sm flex flex-row">
                                                    <FontAwesomeIcon icon={faMapLocation} className={"text-lg mr-2"} /> Calculer
                                                </span>

                                            )}


                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>

                    {error && (
                        <div className="px-3">
                            <div className="alert alert-error my-10">
                                {error}
                            </div>
                        </div>
                    )}



                {queries && (
                    <div id="queries-container" className={"p-3"}>
                        <div className="grid grid-cols-2 gap-6 pt-10">
                            {Object.entries(queries).map(([queryName, queryValue], index) => (
                                <div key={index} className="relative">
                                    <button
                                        className={`btn btn-white btn-sm mt-2 absolute z-10 top-0 right-2 copy-button-${index}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleCopyQuery(index);
                                        }}>Copier
                                    </button>
                                    <div className="mockup-code custom">
                                        <pre data-prefix=">" className="text-warning"><code>{queryName}</code></pre>
                                        <pre><code></code></pre>
                                        <pre><code className={`copy-query-${index}`}> {queryValue} </code></pre>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                    <button type="submit" className="btn  btn-secondary lg:hidden inline-flex w-full ">
                        {loading ? (
                            <span className="loading loading-ring"></span>
                        ) : (
                            <span className="text-sm">
                                    <FontAwesomeIcon icon={faRocketLaunch} className={"text-lg mr-2"} /> Générer les données
                                </span>
                        )}
                    </button>

                </div>

            </div>

        </form>
    );
};

export default Installation;
